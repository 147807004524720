<template>
  <div class="base-now-time-line" :style="nowTimePosition">
    <div class="triangle"></div>
    <div class="line" :style="lineWidth"></div>
  </div>
</template>

<script>
import moment from 'moment'
import { diffTop } from '@/utils/time_table'
import _ from 'lodash'

export default {
  name: 'BaseNowTimeLine',
  props: {
    columnLen: { type: Number, default: 0 },
    startTime: { type: String, default: '0800' },
    columnWidths: { type: Object }
  },
  data() {
    return {
      time: moment().format('HHmm')
    }
  },
  computed: {
    reservationColumnShowIds() {
      return this.$store.getters['staffReservationColumns/getData']
        .filter(v => v.showFlg === 1)
        .map(v => v.reservationColumnId)
    },
    unitHeight() {
      return this.$store.getters['display/getData'].timeTableUnitHeight
    },
    unitWidth() {
      return _.map(this.columnWidths, (value, key) => {
        return this.reservationColumnShowIds.includes(Number(key)) ? value : 0
      }).reduce((sum, value) => (sum += value), 0)
    },
    nowTimePosition() {
      // 26px(column-height) - 15px(triangle / 2) = 11px
      const adjustmentHeight =
        this.$store.getters['display/getColumnSquareHeaderHeight'] + 11
      return {
        top:
          diffTop(this.time, this.startTime, this.unitHeight) +
          adjustmentHeight +
          'px'
      }
    },
    lineWidth() {
      return { width: this.unitWidth + 20 + 'px' }
    }
  },
  created() {
    this.timeObj = setInterval(() => {
      this.tick()
    }, 1 * 60 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.timeObj)
  },
  methods: {
    tick() {
      this.time = moment().format('HHmm')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-now-time-line {
  height: 30px;
  position: absolute;
  left: 55px;
  top: 100px;
  z-index: -1;
  overflow: hidden;
  display: flex;
  > .triangle {
    width: 0;
    height: 0;
    border-left: 15px solid #{$pumpkin};
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
  > .line {
    height: 3px;
    margin: 14px 0 0 -2px;
    background: #{$pumpkin};
  }
}
</style>
