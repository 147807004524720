<template>
  <div class="waiting_status_switch_button">
    <button
      class="button"
      :class="{ clickable, updating }"
      :style="buttonStyle"
      @click.stop="onClick"
      @mouseleave="mouseleave"
      @mouseover="mouseover"
    >
      <transition name="ripple" @enter="startRipple" @after-enter="stopRipple">
        <span v-if="rippling" class="ripple" :ref="'ripple'" />
      </transition>
      <transition :name="transitions.current">
        <div v-if="showCurrent" class="button-contents current">
          <span class="text">{{ currentWaitingStatus.name }}</span>
          <span
            v-if="hasNext && !updating"
            class="triangle right"
            :style="{ backgroundColor: buttonStyle.color }"
          />
        </div>
      </transition>
      <transition :name="transitions.next">
        <div v-if="hasNext && !showCurrent" class="button-contents next">
          <span
            v-if="!updating && useAnimationSlide"
            class="triangle left"
            :style="{ backgroundColor: buttonStyle.color }"
          />
          <span class="text">{{ nextWaitingStatus.name }}</span>
        </div>
      </transition>
    </button>
  </div>
</template>

<script>
import ColorMethods from '@/components/mixins/ColorMethods'

export default {
  name: 'WaitingStatusSwitchButton',

  mixins: [ColorMethods],

  props: {
    isMouseDevice: { type: Boolean },
    lookOnlyFlg: { type: Number },
    reservation: { type: Object },
    updatingReservationId: { type: Number },
    waitingStatuses: { type: Array }
  },

  data() {
    return {
      clickedPosition: { x: 0, y: 0 },
      rippling: false,
      showCurrent: true,
      useAnimationSlide: false
    }
  },

  computed: {
    buttonStyle() {
      const backgroundColor = this.currentWaitingStatus.color
      const color = this.mixinAdaptTextColor(backgroundColor)
      return { backgroundColor, color }
    },
    clickable() {
      return !this.lookOnlyFlg && this.hasNext && !this.updatingReservationId
    },
    currentIndex() {
      return this.waitingStatuses.findIndex(
        v => v.id === this.reservation.waitingStatusId
      )
    },
    nextIndex() {
      return this.waitingStatuses.findIndex(
        v => v.id === this.currentWaitingStatus.nextWaitingStatusId
      )
    },
    currentWaitingStatus() {
      return this.waitingStatuses[this.currentIndex]
    },
    hasNext() {
      return Boolean(this.currentWaitingStatus.nextWaitingStatusId)
    },
    nextWaitingStatus() {
      return this.waitingStatuses[this.nextIndex]
    },
    preventMouseEvent() {
      return !this.isMouseDevice || !this.hasNext || this.updating
    },
    transitions() {
      return this.useAnimationSlide
        ? { current: 'slide-out', next: 'slide-in' }
        : { current: '', next: '' }
    },
    updating() {
      return this.reservation.id === this.updatingReservationId
    }
  },

  watch: {
    updating(newValue, oldValue) {
      const updated = !newValue && oldValue
      if (updated) {
        this.$nextTick(() => {
          this.showCurrent = true
        })
      }
    }
  },

  methods: {
    mouseleave() {
      if (this.preventMouseEvent) return
      this.showCurrent = true
      this.$nextTick(() => {
        this.useAnimationSlide = false
      })
    },
    mouseover() {
      if (this.preventMouseEvent) return
      this.useAnimationSlide = true
      this.$nextTick(() => {
        this.showCurrent = false
      })
    },
    onClick(e) {
      if (!this.clickable) return
      this.useAnimationSlide = false
      this.clickedPosition = { x: e.layerX, y: e.layerY }
      this.rippling = true
      this.showCurrent = false
      this.$emit('click', this.reservation)
    },
    startRipple() {
      const { x, y } = this.clickedPosition
      this.$refs.ripple.style.top = `${y}px`
      this.$refs.ripple.style.left = `${x}px`
    },
    stopRipple() {
      this.rippling = false
    }
  }
}
</script>

<style lang="scss" scoped>
.waiting_status_switch_button {
  > .button {
    border-radius: 6px;
    border: none;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
      0 1px 5px 0 rgb(0 0 0 / 12%);
    height: 28px;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: opacity 0.2s, background-color 0.2s;
    width: 80px;
    &.clickable {
      &:active {
        margin-top: 2px;
        box-shadow: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &.updating {
      margin-top: 2px;
      box-shadow: none;
    }
    > .ripple {
      pointer-events: none;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(lighten(#aaaaaa, 20%), 0.8);
      opacity: 0;
      transform: translate(-50%, -50%) scale(10);
      transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
      &-enter {
        opacity: 1;
        transform: translate(-50%, -50%) scale(0);
      }
    }
    > .button-contents {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      > .triangle {
        position: absolute;
        top: 9.5px;
        width: 8px;
        height: 10px;
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
        &.left {
          left: 4px;
        }
        &.right {
          right: 3px;
        }
      }
      > .text {
        max-width: 50px;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
      }
    }
  }
}

// transition
@keyframes leftOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes rightIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes leftIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes rightOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
.slide-out-leave-active {
  animation: leftOut 0.2s ease-in-out;
}
.slide-out-enter-active {
  animation: rightIn 0.2s ease-in-out;
}
.slide-in-enter-active {
  animation: leftIn 0.2s ease-in-out;
}
.slide-in-leave-active {
  animation: rightOut 0.2s ease-in-out;
}
</style>
