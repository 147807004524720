<template>
  <div class="time-table">
    <time-table-header
      :flashMsgFlg="flashMsgFlg"
      :flashMsg="flashMsg"
      :weekTableDisplayFlg="weekTableDisplayFlg"
      @request-clear="requestClear"
    />
    <time-table-body
      v-if="!weekTableDisplayFlg"
      :initialReservationProp="initialReservation"
      @update-initial-reservation="updateInitialReservation"
      @open-week-table="openWeekTable"
    />
    <time-table-body-week
      v-if="weekTableDisplayFlg"
      @week-update-initial-reservation="weekUpdateInitialReservation"
      @open-one-day-table="$emit('open-one-day-table')"
    />
    <reservation-popup
      v-if="popupFlg"
      :reservationRequestId="reservationRequestId"
      :initialReservationProp="initialReservation"
      @close="closeReservation"
    />
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :type="type"
      @close="alertFlg = false"
      @decision="goToPaymentShowPage"
      @cancel="alertFlg = false"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import TimeTableHeader from '@/components/parts/organisms/TimeTableHeader'
import TimeTableBody from '@/components/parts/organisms/TimeTableBody'
import TimeTableBodyWeek from '@/components/parts/organisms/TimeTableBodyWeek'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import GetPastReservationsByDate from '@/components/mixins/GetPastReservationsByDate'
import moment from 'moment'

export default {
  name: 'TimeTable',
  components: {
    TimeTableHeader,
    ReservationPopup: () => import('@/components/popups/ReservationPopup'),
    TimeTableBody,
    TimeTableBodyWeek,
    AnnouncePopup
  },
  mixins: [GetPastReservationsByDate],
  props: {
    weekTableDisplayFlg: { type: Boolean, default: false }
  },
  data() {
    return {
      initialReservation: null,
      reservationRequestId: 0,
      flashMsgFlg: false,
      flashMsg: '',
      id: null,
      alertFlg: false,
      title: '失敗',
      buttons: ['閉じる'],
      type: 'failure',
      popupMessage: '過去の予約の取得に失敗しました'
    }
  },
  computed: {
    popupFlg() {
      return this.$store.getters['timeTable/get'].popupFlg
    }
    // initialReservation() {
    //   return this.$store.getters['timeTable/get'].initialReservation
    // },
    // reservationRequestId() {
    //   return this.$store.getters['timeTable/get'].reservationRequestId
    // }
  },
  created() {
    this.initialReservation = this.$store.getters[
      'timeTable/get'
    ].initialReservation
    this.reservationRequestId = this.$store.getters[
      'timeTable/get'
    ].reservationRequestId
  },
  beforeDestroy() {
    clearTimeout(this.id)
  },
  destroyed() {
    if (!this.$route.params.owner) {
      this.$store.dispatch('timeTable/deleteInitials')
    }
  },
  methods: {
    updateInitialReservation(initialReservation) {
      this.initialReservation = initialReservation
    },
    openWeekTable() {
      this.$store.dispatch('timeTable/deleteInitials')
      this.requestClear()
      this.$emit('open-week-table')
      const timeTableDate = moment(
        this.$store.getters['timeTable/get'].date
      ).startOf('days')
      this.mixinGetPastReservationsByDate(timeTableDate, true, false)
    },
    weekUpdateInitialReservation(initialReservation) {
      this.initialReservation = initialReservation
      this.$emit('open-one-day-table')
    },
    closeReservation(result) {
      if (result.action === 'create') {
        this.flashMsgFlg = true
        this.flashMsg = result.sendMailFlg
          ? '予約を登録して、メールを送信しました'
          : '予約を登録しました'
      } else if (result.action === 'update') {
        this.flashMsgFlg = true
        const msg = result.sendMailFlg
          ? '予約を編集して、メールを送信しました'
          : '予約を編集しました'
        this.flashMsg = result.isNewOwnerDeleted
          ? msg + '。新規飼主を削除しました'
          : msg
      } else if (result.action === 'delete') {
        this.flashMsgFlg = true
        this.flashMsg = result.sendMailFlg
          ? '予約を削除して、メールを送信しました'
          : '予約を削除しました'
      } else if (result.action === 'cancel') {
        this.flashMsgFlg = true
        this.flashMsg = result.sendMailFlg
          ? '予約をキャンセルして、メールを送信しました'
          : '予約をキャンセルしました'
      } else if (result.action === 'create-repeat') {
        this.flashMsgFlg = true
        this.flashMsg = result.sendMailFlg
          ? '複数予約を登録して、メールを送信しました'
          : '複数予約を登録しました'
      } else if (result.action === 'delete-repeat') {
        this.flashMsgFlg = true
        this.flashMsg = result.sendMailFlg
          ? '複数予約を削除して、メールを送信しました'
          : '複数予約を削除しました'
      }
      this.id = setTimeout(() => {
        this.flashMsgFlg = false
        this.flashMsg = ''
      }, 3000)
      this.initialReservation = null
      this.reservationRequestId = 0
      // this.$store.dispatch('timeTable/deleteInitials')
      this.$store.dispatch('timeTable/deletePopup')
      if (
        this.$route.params.fromPage === 'payment-show' &&
        (result.action === 'create' || result.action === 'create-repeat') &&
        !result.pushToAnotherPageFlg
      ) {
        this.alertFlg = true
        this.title = '確認'
        this.type = 'alert'
        this.buttons = ['戻らない', '戻る']
        this.popupMessage = '会計画面に戻りますか？'
      }
    },
    requestClear() {
      this.initialReservation = null
      this.reservationRequestId = 0
    },
    goToPaymentShowPage() {
      const ownerId = this.$route.params.paymentShowPageIdInfo.ownerId
      const patientId = this.$route.params.paymentShowPageIdInfo.patientId
      const medicalPaymentOriginalId = this.$route.params.paymentShowPageIdInfo
        .medicalPaymentOriginalId
      this.$router.push(
        `/main/karte/owners/${ownerId}/patients/${patientId}/payments/${medicalPaymentOriginalId}/show`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.time-table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
}
</style>
