<template>
  <div class="main-right-calendar">
    <template>
      <v-date-picker
        ref="calendar"
        v-model="selectDate"
        :attributes="attrs"
        mode="single"
        :title-position="'left'"
        color="orange"
        is-inline
        is-required
        style="width: 328px; margin: 0 auto;"
        data-test="v-date-picker"
        @update:toPage="updateCalendar"
      >
        <template slot="header-title" slot-scope="page">
          {{ page.yearLabel }}年{{ page.monthLabel }}
        </template>
      </v-date-picker>
    </template>
  </div>
</template>

<script>
import GetPastReservationsByDate from '@/components/mixins/GetPastReservationsByDate'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'MainRightCalendar',
  mixins: [GetPastReservationsByDate],
  props: {
    weekTableDisplayFlg: { type: Boolean, default: false }
  },
  data() {
    return {
      yearMonth: `${this.$store.getters['timeTable/get'].date}`.substring(0, 6),
      mounted: false
    }
  },
  computed: {
    ...mapGetters({
      openTimes: 'openTimes/getData',
      publicHolidays: 'master/getPublicHolidays',
      specialOpenTimes: 'specialOpenTimes/getData',
      getReservationAvailablePattern: 'reservationAvailablePatterns/getDataById'
    }),
    selectDate: {
      get() {
        const date = this.$store.getters['timeTable/get'].date
        const year = Number(date.substring(0, 4))
        const month = Number(date.substring(4, 6))
        const day = Number(date.substring(6, 8))
        return new Date(year, month - 1, day)
      },
      async set(val) {
        const date = moment(val).startOf('day')
        this.$store.dispatch(
          'timeTable/changeDate',
          moment(date).format('YYYYMMDD')
        )
        this.mixinGetPastReservationsByDate(
          date,
          this.weekTableDisplayFlg,
          true
        )
      }
    },
    filterHolidays() {
      return this.publicHolidays
        .filter(h => h.substring(0, 6) === this.yearMonth)
        .map(h => Number(h.substring(6, 8)))
    },
    dateArray() {
      return Array(moment(this.yearMonth, 'YYYYMM').daysInMonth())
        .fill('')
        .map((v, i) => this.yearMonth + ('00' + (i + 1)).slice(-2))
    },
    closeDays() {
      return this.dateArray
        .map(date => {
          const specialOpenTime = this.specialOpenTimes.find(
            v => v.date === date
          )
          if (specialOpenTime) {
            const reservationAvailablePattern = this.getReservationAvailablePattern(
              specialOpenTime.reservationAvailablePatternId
            )
            return reservationAvailablePattern.closedDayFlg === 1
              ? Number(date.substring(6, 8))
              : ''
          } else {
            const publicHoliday = this.publicHolidays.find(v => v === date)
            const type = publicHoliday
              ? 8
              : moment(date, 'YYYYMMDD').weekday() + 1
            const openTime = this.openTimes.find(v => v.type === type)
            return openTime.closeFlg === 1 ? Number(date.substring(6, 8)) : ''
          }
        })
        .filter(v => v !== '')
    },
    attrs() {
      return [
        {
          key: 'today',
          bar: true,
          dates: new Date()
        },
        {
          key: 'saturday',
          highlight: {
            color: 'blue',
            fillMode: 'light'
          },
          dates: { weekdays: [7] }
        },
        {
          key: 'sunday',
          highlight: {
            color: 'red',
            fillMode: 'light'
          },
          dates: { weekdays: [1] }
        },
        {
          key: 'holidays',
          highlight: {
            color: 'purple',
            fillMode: 'light'
          },

          dates: { days: this.filterHolidays }
        },
        {
          key: 'any',
          highlight: {
            class: 'gray-background vc-rounded-full',
            contentClass: 'dark-gray-text'
          },
          dates: { days: this.closeDays }
        }
      ]
    }
  },
  watch: {
    selectDate(date) {
      this.$refs.calendar.$refs.calendar.showPageRange({
        from: {
          month: date.getMonth() + 1,
          year: date.getFullYear()
        }
      })
    }
  },
  mounted() {
    this.mounted = true
  },
  methods: {
    updateCalendar() {
      if (this.mounted) {
        const page = this.$refs.calendar.$refs.calendar.pages[0]
        this.yearMonth = `${page.year}` + `${page.month}`.padStart(2, '0')
      }
    }
  }
}
</script>
<style lang="scss" deep>
.main-right-calendar {
  > .vc-border {
    border-style: none;
  }
  .gray-background {
    background-color: #{$medium-grey} !important;
  }
  .dark-gray-text {
    color: #{$dark-grey} !important;
  }
}
.my-day {
  padding-top: 2.5px;
  height: 32px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
</style>
