<template>
  <div class="time-table-week-one-day">
    <div class="day">{{ dateFormatter }}</div>
    <div class="column-area">
      <reservation-column-week
        v-for="reservationColumn in filteredReservationColumns"
        :key="reservationColumn.id"
        :weekReservations="weekReservations"
        :date="date"
        :startTime="startTime"
        :endTime="endTime"
        :reservationColumn="reservationColumn"
        :workTimeStyles="workTimeStyles"
        @week-update-initial-reservation="
          data => $emit('week-update-initial-reservation', data)
        "
      />
    </div>
  </div>
</template>
<script>
import ReservationColumnWeek from '@/components/parts/organisms/ReservationColumnWeek'
import moment from 'moment'
import { getWorkTimeStyles } from '@/utils/time_table'

export default {
  name: 'TimeTableWeekOneDay',
  components: {
    ReservationColumnWeek
  },
  props: {
    weekReservations: { type: Array },
    date: { type: String },
    startTime: { type: String },
    endTime: { type: String },
    filteredReservationColumns: { type: Array }
  },
  computed: {
    dateFormatter() {
      return moment(this.date).format('M月D日(dd)')
    },
    workTimeStyles() {
      return getWorkTimeStyles(this.date, this.startTime, true)
    }
  }
}
</script>
<style lang="scss" scoped>
.time-table-week-one-day {
  flex-grow: 1;
  margin-right: 10px;
  > .day {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 26px;
    background-color: #{$white};
    pointer-events: none;
    border-left: solid 1px #{$light-grey};
    border-right: solid 1px #{$light-grey};
    box-sizing: border-box;
  }
  > .column-area {
    display: flex;
  }
}
</style>
