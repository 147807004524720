<template>
  <div class="reservation-column-week">
    <div class="column-name" :title="reservationColumn.name">
      {{ reservationColumn.name }}
    </div>
    <div class="column-square-area">
      <div
        class="column-square"
        v-for="label in indicateLabels"
        :key="label"
        :style="styles"
        @click="openNewReservation($event, label)"
      />
    </div>
    <div class="column-square-footer"></div>
    <div
      class="reservation-item"
      v-for="reservation in reservations"
      :key="reservation.id"
      :style="reservationStyles(reservation)"
      @click="openReservation(reservation)"
    />
    <div class="outside-working-time"></div>
    <div
      class="working-time"
      v-for="workTimeStyle in workTimeStyles"
      :key="workTimeStyle.id + '-work'"
      :style="workTimeStyle"
    ></div>
    <div
      class="off-time"
      v-for="offTimeStyle in offTimeStyles"
      :key="offTimeStyle.id + '-off'"
      :style="offTimeStyle"
    ></div>
  </div>
</template>
<script>
import moment from 'moment'
import { indicateLabels, diffTop, getOffTimeStyles } from '@/utils/time_table'
import ColorMethods from '@/components/mixins/ColorMethods'
import { mapGetters } from 'vuex'
import { WEEK_RESERVATION_COLUMN_HEADER_HEIGHT } from '@/utils/define'

export default {
  name: 'ReservationColumnWeek',
  mixins: [ColorMethods],
  props: {
    weekReservations: { type: Array },
    date: { type: String },
    startTime: { type: String },
    endTime: { type: String },
    reservationColumn: { type: Object },
    workTimeStyles: { type: Array }
  },
  computed: {
    ...mapGetters({
      timeTable: 'timeTable/get',
      display: 'display/getData',
      getReservationPurpose: 'reservationPurposes/getDataById'
    }),
    indicateLabels() {
      return indicateLabels(this.startTime, this.endTime).slice(0, -1)
    },
    reservations() {
      return this.weekReservations.filter(v => {
        return (
          v.date === this.date &&
          v.reservationColumnId === this.reservationColumn.id
        )
      })
    },
    styles() {
      return { height: this.timeTable.unitHeightForWeekTable + 'px' }
    },
    offTimeStyles() {
      return getOffTimeStyles(
        this.reservationColumn,
        this.date,
        this.startTime,
        true
      )
    }
  },
  methods: {
    openNewReservation(event, label) {
      const columnHeight = this.timeTable.unitHeightForWeekTable
      const timeUnit = this.$store.getters['reservationSetting/getData']
        .reservationTimeUnit
      const clickMinute =
        parseInt(((event.offsetY / columnHeight) * 60) / timeUnit) * timeUnit
      const startTime = label.substring(0, 2) + ('00' + clickMinute).slice(-2)
      const endTime = moment(startTime, 'HHmm')
        .add(timeUnit, 'minutes')
        .format('HHmm')
      this.$store.dispatch('timeTable/changeDate', this.date)
      this.$emit('week-update-initial-reservation', {
        date: this.date,
        reservationColumnId: this.reservationColumn.id,
        startTime,
        endTime
      })
      this.$store.dispatch('timeTable/showPopup')
    },
    openReservation(reservation) {
      const initialReservation = this.$store.getters[
        'reservations/getDataById'
      ](reservation.id)
      this.$store.dispatch('timeTable/changeDate', this.date)
      this.$emit('week-update-initial-reservation', initialReservation)
      this.$store.dispatch('timeTable/showPopup')
    },
    reservationStyles(reservation) {
      const top = diffTop(
        reservation.startTime,
        this.startTime,
        this.timeTable.unitHeightForWeekTable
      )
      const itemH = diffTop(
        reservation.endTime,
        reservation.startTime,
        this.timeTable.unitHeightForWeekTable
      )
      const reservationPurposeColor = this.getReservationPurpose(
        reservation.reservationPurposeId
      )?.color
      const staffColor = this.$store.getters['staffs/getDataById'](
        reservation.staffId
      )?.color
      const colors = {
        reservationColumn: this.reservationColumn.color,
        reservationPurpose: reservationPurposeColor,
        staff: staffColor
      }
      const backgroundColor =
        colors[this.display.colorOrder[0]] ||
        colors[this.display.colorOrder[1]] ||
        colors[this.display.colorOrder[2]]
      const styles = {
        top: top + WEEK_RESERVATION_COLUMN_HEADER_HEIGHT + 'px',
        width: '100%',
        minWidth: '44px',
        height: itemH - 1 + 'px',
        backgroundColor,
        color: this.mixinAdaptTextColor(backgroundColor)
      }
      return styles
    }
  }
}
</script>
<style lang="scss" scoped>
.reservation-column-week {
  width: 100%;
  min-width: 44px;
  position: relative;
  > .column-name {
    position: sticky;
    position: -webkit-sticky;
    top: 26px;
    z-index: 10;
    width: 100%;
    min-width: 44px;
    height: 80px;
    padding-top: 4px;
    display: flex;
    align-items: center;
    background-color: #{$white};
    writing-mode: vertical-lr;
    text-orientation: upright;
    overflow: hidden;
    white-space: nowrap;
    border-top: solid 1px #{$light-grey};
    border-bottom: solid 1px #{$light-grey};
    border-left: solid 1px #{$light-grey};
    box-sizing: border-box;
    font-size: 15px;
  }
  > .column-square-area {
    > .column-square {
      width: 100%;
      border-bottom: solid 1px #{$light-grey};
      border-left: solid 1px #{$light-grey};
      box-sizing: border-box;
    }
  }
  > .column-square-footer {
    border-left: solid 1px #{$light-grey};
    height: 45px;
    background-color: #{$medium-grey};
  }
  > .reservation-item {
    position: absolute;
    border-radius: 6px;
    box-sizing: border-box;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  > .off-time {
    position: absolute;
    width: 100%;
    background-color: #{$medium-grey};
    left: 0;
    z-index: -10;
  }
  > .outside-working-time {
    box-sizing: border-box;
    position: absolute;
    // ↓.column-name
    top: 80px;
    left: 0;
    z-index: -20;
    // ↓.column-name + .column-square-footer
    height: calc(100% - 125px);
    width: 100%;
    background-color: #{$medium-grey};
  }
  > .working-time {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    z-index: -15;
    width: 100%;
    background-color: #{$white};
  }
}
.reservation-column-week:last-child {
  border-right: solid 1px #{$light-grey};
}
</style>
