<template>
  <div class="time-table-indicator">
    <div class="time-table-indicator-area">
      <div
        v-for="(label, index) in indicateLabels"
        :key="index"
        :style="styles(label, index)"
        class="indicator-wrapper"
      >
        <div class="time-indicator" :style="timeIndicatorStyles">
          {{ label }}
        </div>
      </div>
      <div
        v-if="dragModeFlg"
        class="drag-reservation-start-time"
        :style="dragStyles"
      >
        {{ dragReservationStartTime }}
      </div>
    </div>
  </div>
</template>
<script>
import { indicateLabels, diffTop } from '@/utils/time_table'
import {
  WEEK_DATE_HEADER_HEIGHT,
  WEEK_RESERVATION_COLUMN_HEADER_HEIGHT
} from '@/utils/define'
export default {
  name: 'TimeTableIndicator',
  props: {
    startTime: {
      type: String,
      default: '0800'
    },
    endTime: {
      type: String,
      default: '2100'
    },
    dragModeFlg: { type: Boolean, default: false },
    dragReservationObj: {
      type: Object,
      default() {
        return {
          reservation: { id: 0 },
          reservationColumnId: 0,
          startTime: '',
          endTime: ''
        }
      }
    },
    weekTableDisplayFlg: { type: Boolean, default: false }
  },
  computed: {
    indicateLabels() {
      return indicateLabels(this.startTime, this.endTime)
    },
    unitHeight() {
      return this.$store.getters['display/getData'].timeTableUnitHeight
    },
    styles() {
      return (label, index) => {
        if (label === this.addColon() && this.dragModeFlg) {
          return {
            height: this.unitHeight + 'px',
            fontWeight: 'bold',
            color: '#ef6c00' // $pumpkin
          }
        } else if (this.weekTableDisplayFlg) {
          return index === 0
            ? {
                height:
                  WEEK_DATE_HEADER_HEIGHT +
                  WEEK_RESERVATION_COLUMN_HEADER_HEIGHT +
                  'px',
                position: 'relative'
              }
            : {
                height:
                  this.$store.getters['timeTable/get'].unitHeightForWeekTable +
                  'px',
                position: 'relative'
              }
        } else {
          return { height: this.unitHeight + 'px' }
        }
      }
    },
    timeIndicatorStyles() {
      return this.weekTableDisplayFlg
        ? { position: 'absolute', bottom: '-8px', left: '7px' }
        : {}
    },
    dragReservationStartTime() {
      return this.dragReservationObj.startTime.slice(2, 4) !== '00'
        ? this.addColon()
        : ''
    },
    dragStyles() {
      if (this.dragReservationObj.startTime !== '') {
        const top = diffTop(
          this.dragReservationObj.startTime,
          this.startTime,
          this.unitHeight
        )
        return {
          top:
            top +
            this.$store.getters['display/getTimetableBodyHeaderHeight'] +
            'px'
        }
      } else {
        return {}
      }
    }
  },
  methods: {
    addColon() {
      return this.dragReservationObj.startTime !== ''
        ? this.dragReservationObj.startTime.slice(0, 2) +
            ':' +
            this.dragReservationObj.startTime.slice(2, 4)
        : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.time-table-indicator {
  > .time-table-indicator-area {
    position: relative;
    > .indicator-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      padding-left: 8px;
      > .time-indicator {
        font-size: 13px;
      }
    }
    > .drag-reservation-start-time {
      position: absolute;
      width: 40px;
      padding-left: 8px;
      font-size: 13px;
      font-weight: bold;
      color: #{$pumpkin};
    }
  }
}
</style>
