<template>
  <div class="reservation-column-slide-tab">
    <div class="header" @click="toggleBody" :style="cursorStyle">
      <div class="group-name" data-test="title">
        <div class="sort-mark" v-if="sortModeFlg">
          <img
            src="@/assets/images/menu.png"
            width="21px"
            height="14px"
            alt="menu"
          />
        </div>
        {{ reservationColumnGroupName }}（{{ reservationColumns.length }}）
      </div>
      <img
        class="arrow"
        :class="{ up: displayBodyFlg, down: !displayBodyFlg }"
        src="@/assets/images/allow2.png"
        width="10px"
        height="15px"
        alt="allow2"
      />
    </div>
    <div class="body" v-show="displayBodyFlg">
      <draggable
        class="draggable"
        :sort="sortModeFlg"
        v-model="displayReservationColumns"
      >
        <div
          class="row"
          v-for="reservationColumn in displayReservationColumns"
          :key="reservationColumn.id"
          :style="cursorStyle2"
        >
          <base-color-check-box
            v-if="!sortModeFlg"
            :checkFlg="showFlg(reservationColumn)"
            :color="reservationColumn.color"
            @click="toggleShowFlg(reservationColumn.staffReservationColumnId)"
          />
          <div class="sort-mark" v-if="sortModeFlg">
            <img
              src="@/assets/images/menu.png"
              width="19px"
              height="13px"
              alt="menu"
            />
          </div>
          <div class="name" data-test="body-name">
            {{ reservationColumn.name }}
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import BaseColorCheckBox from '@/components/parts/atoms/BaseColorCheckBox'
import Draggable from 'vuedraggable'
import _ from 'lodash'

export default {
  name: 'ReservationColumnSlideTab',
  components: { BaseColorCheckBox, Draggable },
  props: {
    index: { type: Number },
    reservationColumnGroupId: { type: Number },
    reservationColumnGroupName: { type: String },
    reservationColumns: { type: Array },
    sortModeFlg: { type: Boolean, default: false }
  },
  data() {
    return {
      displayBodyFlg: true,
      bodyData: [],
      beforeBodyData: []
    }
  },
  computed: {
    showFlg: function() {
      return function(reservationColumn) {
        const staffReservationColumns = _.cloneDeep(
          this.$store.getters['staffReservationColumns/getData']
        )
        const staffReservationColumn = staffReservationColumns.find(
          v => v.id === reservationColumn.staffReservationColumnId
        )
        if (staffReservationColumn) {
          return staffReservationColumn.showFlg === 1 ? true : false
        } else {
          return false
        }
      }
    },
    cursorStyle() {
      return this.sortModeFlg === true ? 'cursor: move;' : 'cursor: pointer;'
    },
    cursorStyle2() {
      return this.sortModeFlg === true ? 'cursor: move;' : 'cursor: text;'
    },
    displayReservationColumns: {
      get() {
        return this.sortModeFlg ? this.bodyData : this.beforeBodyData
      },
      set(data) {
        const orderIds = data.map(v => v.staffReservationColumnId)
        this.$emit('order', { index: this.index, data, orderIds })
      }
    }
  },
  watch: {
    sortModeFlg: function() {
      this.setData()
    },
    reservationColumns: {
      handler: function() {
        this.setData()
      },
      deep: true
    }
  },
  created() {
    this.setData()
  },
  methods: {
    toggleBody() {
      this.displayBodyFlg = !this.displayBodyFlg
    },
    async toggleShowFlg(id) {
      const result = await this.$store.dispatch(
        'staffReservationColumns/toggleShowFlg',
        id
      )
      if (result !== true) this.$emit('error', result)
    },
    setData() {
      this.bodyData = this.reservationColumns
      this.beforeBodyData = _.cloneDeep(this.bodyData)
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-column-slide-tab {
  width: 315px;
  > .header {
    height: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    > .group-name {
      font-size: 15px;
      font-weight: bold;
      display: flex;
      align-items: center;
      > .sort-mark {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    }
    > .arrow {
      margin-right: 16px;
      width: 10px;
      height: 15px;
      &.up {
        transform: rotate(90deg);
      }
      &.down {
        transform: rotate(-90deg);
      }
    }
  }
  > .body {
    margin-top: 20px;
    > .draggable {
      > .row {
        height: 20px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        & ~ .row {
          margin-top: 20px;
        }
        &:hover {
          opacity: 0.8;
        }
        > .sort-mark {
          display: flex;
          align-items: center;
        }
        > .name {
          font-size: 13px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
