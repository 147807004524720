import moment from 'moment'

export default {
  methods: {
    mixinGetPastReservationsByDate: async function(
      timeTableDate,
      weekTableDisplayFlg,
      mainRightCalendarFlg
    ) {
      const pastDate = moment().subtract(7, 'days')
      if (timeTableDate.isBefore(pastDate, 'day')) {
        let dates
        if (weekTableDisplayFlg) {
          const diff = pastDate.diff(timeTableDate, 'days')
          const value = diff > 7 ? 7 : diff
          dates = [...Array(value)].map((_, i) =>
            moment(timeTableDate)
              .add(i, 'days')
              .format('YYYYMMDD')
          )
        } else {
          dates = [moment(timeTableDate).format('YYYYMMDD')]
        }
        const obtainedDates = this.$store.getters[
          'reservations/getObtainedDates'
        ]
        const reqDates = dates.filter(date => !obtainedDates.includes(date))
        if (reqDates.length === 0) return
        const res = await this.$store.dispatch(
          'reservations/getPastData',
          reqDates
        )
        if (res) {
          this.$store.dispatch('reservations/addDates', reqDates)
        } else {
          if (mainRightCalendarFlg) {
            this.$emit('error')
          } else {
            this.alertFlg = true
            this.title = '失敗'
            this.buttons = ['閉じる']
            this.type = 'failure'
            this.popupMessage = '過去の予約の取得に失敗しました'
          }
        }
      }
    }
  }
}
