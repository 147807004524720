<template>
  <div
    class="base-color-check-box"
    @click="check"
    data-test="base-color-check-box"
  >
    <div
      class="check-box"
      v-if="!checkFlg"
      :style="`border: solid 2px ${color};`"
      data-test="check-box"
    ></div>
    <div
      class="checked-box"
      v-if="checkFlg"
      :style="style"
      data-test="checked-box"
    >
      <div class="check-mark" :style="style"></div>
    </div>
  </div>
</template>

<script>
import ColorMethods from '@/components/mixins/ColorMethods'

export default {
  name: 'BaseColorCheckBox',
  mixins: [ColorMethods],
  props: {
    checkFlg: { type: Boolean, default: false },
    color: { type: String, default: '#ffa52b' }
  },
  computed: {
    style() {
      return {
        backgroundColor: this.color,
        borderColor: this.mixinAdaptTextColor(this.color)
      }
    }
  },
  methods: {
    check() {
      this.$emit('click', !this.checkFlg)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-color-check-box {
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  > .check-box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: white;
    box-sizing: border-box;
  }
  > .checked-box {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    > .check-mark {
      content: '';
      border: solid 2px white;
      border-top: none;
      border-right: none;
      display: block;
      position: absolute;
      width: 8px;
      height: 4px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      left: 5px;
      top: 6px;
    }
  }
}
</style>
