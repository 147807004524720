<template>
  <div class="main-contents">
    <time-table
      :weekTableDisplayFlg="weekTableDisplayFlg"
      @open-week-table="weekTableDisplayFlg = true"
      @open-one-day-table="weekTableDisplayFlg = false"
    />
    <main-right-menu :weekTableDisplayFlg="weekTableDisplayFlg" />
  </div>
</template>

<script>
import MainRightMenu from '@/components/parts/organisms/MainRightMenu'
import TimeTable from '@/components/parts/organisms/TimeTable'
export default {
  name: 'MainContents',
  components: {
    TimeTable,
    MainRightMenu
  },
  data() {
    return {
      weekTableDisplayFlg: false
    }
  }
}
</script>

<style lang="scss" scoped>
.main-contents {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
  min-width: 0;
}
</style>
