<template>
  <div class="time-table-body-week">
    <div class="blank-cell">
      <base-button-small-white
        :styles="{ width: '70px' }"
        @click="$emit('open-one-day-table')"
        >{{ '日毎表示' }}</base-button-small-white
      >
    </div>
    <time-table-indicator
      class="float-indicator"
      :startTime="tableStartEndTime.startTime"
      :endTime="tableStartEndTime.endTime"
      :weekTableDisplayFlg="true"
    />
    <div class="wrapper">
      <div class="left-line-wrapper">
        <div class="left-line-spacer"></div>
        <div class="left-line-header" :style="leftLineHeaderStyles"></div>
        <div
          class="left-line"
          :style="lineStyles"
          v-for="(label, index) in indicateLabelsShort"
          :key="index"
        ></div>
      </div>
      <div class="time-table-week">
        <time-table-week-one-day
          v-for="(date, index) in weekDates"
          :key="index"
          :weekReservations="weekReservations"
          :date="date"
          :startTime="tableStartEndTime.startTime"
          :endTime="tableStartEndTime.endTime"
          :filteredReservationColumns="filteredReservationColumns"
          @week-update-initial-reservation="
            data => $emit('week-update-initial-reservation', data)
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import TimeTableIndicator from '@/components/parts/organisms/TimeTableIndicator'
import TimeTableWeekOneDay from '@/components/parts/organisms/TimeTableWeekOneDay'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import {
  indicateLabels,
  getFilteredReservationColumns,
  getTimeTableStartEnd
} from '@/utils/time_table'
import { WEEK_RESERVATION_COLUMN_HEADER_HEIGHT } from '@/utils/define'

export default {
  name: 'TimeTableBodyWeek',
  components: {
    BaseButtonSmallWhite,
    TimeTableIndicator,
    TimeTableWeekOneDay
  },
  computed: {
    ...mapGetters({
      timeTable: 'timeTable/get',
      reservations: 'reservations/getData'
    }),
    leftLineHeaderStyles() {
      return { height: WEEK_RESERVATION_COLUMN_HEADER_HEIGHT - 1 + 'px' }
    },
    lineStyles() {
      return { height: this.timeTable.unitHeightForWeekTable + 'px' }
    },
    indicateLabelsShort() {
      const startEnd = this.tableStartEndTime
      const labels = indicateLabels(startEnd.startTime, startEnd.endTime)
      return labels.splice(0, labels.length - 1)
    },
    reservationColumns() {
      return _.cloneDeep(this.$store.getters['reservationColumns/getData'])
    },
    staffReservationColumns() {
      return _.cloneDeep(this.$store.getters['staffReservationColumns/getData'])
    },
    filteredReservationColumns() {
      return getFilteredReservationColumns(
        this.reservationColumns,
        this.staffReservationColumns,
        true
      )
    },
    weekDates() {
      const weekDates = [...Array(7)].map((_, i) => {
        return moment(this.timeTable.date)
          .add(i, 'd')
          .format('YYYYMMDD')
      })
      return weekDates
    },
    weekReservations() {
      return this.reservations.filter(v => {
        const isSelectedColumnReservation = this.filteredReservationColumns.some(
          reservationColumn => reservationColumn.id === v.reservationColumnId
        )
        return (
          this.weekDates.includes(v.date) &&
          v.cancelFlg === 0 &&
          v.delFlg === 0 &&
          isSelectedColumnReservation
        )
      })
    },
    tableStartEndTime() {
      return getTimeTableStartEnd(this.weekDates, this.weekReservations)
    }
  }
}
</script>
<style lang="scss" scoped>
.time-table-body-week {
  display: flex;
  overflow-y: auto;
  position: relative;
  > .blank-cell {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    left: 0px;
    z-index: 20;
    width: 74px;
    height: 26px;
    background-color: #{$white};
  }
  > .float-indicator {
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    z-index: 10;
    pointer-events: none;
    margin-left: -74px; // 日毎表示ボタンの横幅の分、左にずらす
  }
  > .wrapper {
    display: flex;
    width: 100%;
    > .left-line-wrapper {
      min-width: 26px;
      > .left-line-spacer {
        height: 26px;
      }
      > .left-line-header {
        height: 45px;
        border-bottom: solid 1px #{$light-grey};
      }
      > .left-line {
        box-sizing: border-box;
        border-bottom: solid 1px #{$light-grey};
      }
    }
    > .time-table-week {
      display: flex;
      width: 100%;
      align-items: flex-start;
    }
  }
}
</style>
