<template>
  <div class="time-table-header-change-date">
    <div class="back" @click="backDate" data-test="back">
      <div class="wrap"><div class="back-arrow"></div></div>
    </div>
    <div v-if="!weekTableDisplayFlg" class="date" data-test="date">
      <span class="year">{{ date.year }}</span
      ><span class="month">{{ date.month }}</span
      ><span class="day">{{ date.day }}</span
      ><span class="w-day">{{ date.wDay }}</span>
    </div>
    <div
      v-if="weekTableDisplayFlg"
      class="date-week-table"
      data-test="date-week-table"
    >
      <span class="first">{{ week.first }}</span
      ><span class="wavy-line">～</span
      ><span class="last">{{ week.last }}</span>
    </div>
    <div class="next" @click="nextDate" data-test="next">
      <div class="wrap"><div class="next-arrow"></div></div>
    </div>
    <base-button-small-white
      @click="backToday"
      class="button"
      :styles="{ width: '80px' }"
      >今日</base-button-small-white
    >
  </div>
</template>

<script>
import moment from 'moment'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
export default {
  name: 'TimeTableHeaderChangeDate',
  components: {
    BaseButtonSmallWhite
  },
  props: {
    weekTableDisplayFlg: { type: Boolean, default: false }
  },
  computed: {
    selectDate() {
      return this.$store.getters['timeTable/get'].date
    },
    date() {
      return {
        year: moment(this.selectDate).format('YYYY年'),
        month: moment(this.selectDate).format('M月'),
        day: moment(this.selectDate).format('D日'),
        wDay: moment(this.selectDate).format('（dd）')
      }
    },
    week() {
      // class="date-week-table" 1週間のタイムテーブル表示の日付表示の要素
      // 全体の画面の幅を縮小した時に、この要素のwidthの値を変える必要があるため、class="date"とは別の要素に分けました
      // 非表示の時にこの処理が毎回実行されないようにv-ifにしました
      return {
        first: moment(this.selectDate).format('YYYY年M月D日'),
        last: moment(this.selectDate)
          .add(6, 'd')
          .format('YYYY年M月D日')
      }
    }
  },
  methods: {
    async backToday() {
      this.$store.dispatch('timeTable/changeDate', moment().format('YYYYMMDD'))
    },
    backDate() {
      this.weekTableDisplayFlg
        ? this.$store.dispatch(
            'timeTable/changeDate',
            moment(this.selectDate)
              .subtract(7, 'd')
              .format('YYYYMMDD')
          )
        : this.$store.dispatch(
            'timeTable/changeDate',
            moment(this.selectDate)
              .subtract(1, 'd')
              .format('YYYYMMDD')
          )
    },
    nextDate() {
      this.weekTableDisplayFlg
        ? this.$store.dispatch(
            'timeTable/changeDate',
            moment(this.selectDate)
              .add(7, 'd')
              .format('YYYYMMDD')
          )
        : this.$store.dispatch(
            'timeTable/changeDate',
            moment(this.selectDate)
              .add(1, 'd')
              .format('YYYYMMDD')
          )
    }
  }
}
</script>

<style lang="scss">
.time-table-header-change-date {
  height: 33px;
  display: flex;
  align-items: center;
  > .date {
    padding-left: 3px;
    display: flex;
    align-items: center;
    font-feature-settings: 'palt';
    font-size: 30px;
    font-weight: bold;
    text-align: right;
    > .year {
      width: 102px;
    }
    > .month {
      width: 68px;
    }
    > .day {
      width: 68px;
    }
    > .w-day {
      width: 64px;
    }
  }
  > .date-week-table {
    padding-left: 3px;
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    > .first {
      width: 240px;
    }
    > .last {
      width: 240px;
    }
  }
  > .back,
  .next {
    height: 33px;
    width: 25px;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
      background-color: #{$light-grey};
      border-radius: 20%;
    }
    > .wrap {
      position: relative;
      z-index: -1;
      > .back-arrow {
        position: absolute;
        top: 11px;
        left: 10px;
        display: block;
        width: 10px;
        height: 10px;
        border-left: 2px solid #{$gray};
        border-bottom: 2px solid #{$gray};
        transform: rotate(45deg);
      }
      > .next-arrow {
        position: absolute;
        top: 11px;
        left: 4px;
        display: block;
        width: 10px;
        height: 10px;
        border-right: 2px solid #{$gray};
        border-top: 2px solid #{$gray};
        transform: rotate(45deg);
      }
    }
  }
  > .button {
    margin-left: 18px;
  }
}
@media (max-width: $tablet-width) {
  .time-table-header-change-date {
    > .date-week-table {
      font-size: 23px;
      > .first {
        width: 190px;
      }
      > .last {
        width: 190px;
      }
    }
  }
}
</style>
