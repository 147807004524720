import store from '@/store/store'
import moment from 'moment'
import { WEEK_RESERVATION_COLUMN_HEADER_HEIGHT } from '@/utils/define'

export const indicateLabels = (startTime, endTime) => {
  // startTime と endTime の間の表示時間の列を返す
  const start = parseInt(startTime.slice(0, 2))
  const end = parseInt(endTime.slice(0, 2))
  let ret = []
  for (let i = start; i <= end; i++) {
    ret.push(('0' + i).slice(-2) + ':00')
  }
  return ret
}

export const hourUnit = str => {
  return parseInt(str.substring(0, 2)) + parseInt(str.substring(2, 4)) / 60
}

export const diffTop = (time, startTime, unitHeight) => {
  return (hourUnit(time) - hourUnit(startTime)) * unitHeight
}

export const getFilteredReservationColumns = (
  reservationColumns,
  staffReservationColumns,
  weekTableDisplayFlg = false
) => {
  const initialReservation = weekTableDisplayFlg
    ? null
    : store.getters['timeTable/get'].initialReservation
  const date = store.getters['timeTable/get'].date
  return reservationColumns
    .map(v => {
      const staffReservationColumn = staffReservationColumns.find(
        x => x.reservationColumnId === v.id
      )
      v.showFlg = staffReservationColumn.showFlg
      if (
        initialReservation &&
        initialReservation.reservationColumnId === v.id &&
        initialReservation.date === date
      ) {
        v.showFlg = 1
      }
      v.order = staffReservationColumn.order
      return v
    })
    .filter(v => v.showFlg === 1)
    .sort((a, b) => {
      return a.order > b.order ? 1 : -1
    })
}

export const getTimeTableStartEnd = (date, reservations) => {
  let startTime, endTime
  const base = store.getters['openTimes/getData'].find(v => v.type === 0)
  startTime = base.start
  endTime = base.end
  if (Array.isArray(date)) {
    date.forEach(date => {
      const result = compareWithOpenTime(date, startTime, endTime)
      startTime = result.startTime
      endTime = result.endTime
    })
  } else {
    const result = compareWithOpenTime(date, startTime, endTime)
    startTime = result.startTime
    endTime = result.endTime
  }
  reservations.forEach(v => {
    startTime = Math.min(startTime, v.startTime)
    endTime = Math.max(endTime, v.endTime)
  })
  startTime =
    String(startTime)
      .padStart(4, 0)
      .substring(0, 2) + '00'
  endTime = String(endTime).padStart(4, 0)
  if (endTime.slice(2) !== '00') {
    const hour = Number(endTime.slice(0, 2)) + 1
    endTime = ('0000' + hour + '00').slice(-4)
  }
  return { startTime, endTime }
}

export const compareWithOpenTime = (date, baseStartTime, baseEndTime) => {
  const openTime = getOpenTime(date)
  let startTime = Math.min(baseStartTime, openTime.start)
  let endTime = Math.max(baseEndTime, openTime.end)
  const specialOpenTime = store.getters['specialOpenTimes/getData'].find(
    v => v.date === date
  )
  if (specialOpenTime) {
    const reservationAvailablePattern = store.getters[
      'reservationAvailablePatterns/getDataById'
    ](specialOpenTime.reservationAvailablePatternId)
    if (
      reservationAvailablePattern &&
      reservationAvailablePattern.baseTimeFlg === 0
    ) {
      startTime = Math.min(
        startTime,
        reservationAvailablePattern.times[0].substring(0, 4)
      )
      endTime = Math.max(
        endTime,
        reservationAvailablePattern.times[
          reservationAvailablePattern.times.length - 1
        ].substring(4)
      )
    }
  }
  return { startTime, endTime }
}

export const getOpenTime = date => {
  if (store.getters['master/getPublicHolidays'].includes(date)) {
    return store.getters['openTimes/getData'].find(v => v.type === 8)
  } else {
    const day = moment(date, 'YYYYMMDD').day() + 1
    return store.getters['openTimes/getData'].find(v => v.type === day)
  }
}

export const mapStyles = (array, tableStartTime, weekTableDisplayFlg) => {
  return array.map((v, i) => {
    const top = diffTop(
      v.startTime,
      tableStartTime,
      weekTableDisplayFlg
        ? store.getters['timeTable/get'].unitHeightForWeekTable
        : store.getters['display/getData'].timeTableUnitHeight
    )
    return {
      id: i + 1,
      top: weekTableDisplayFlg
        ? top + WEEK_RESERVATION_COLUMN_HEADER_HEIGHT + 'px'
        : top + store.getters['display/getTimetableBodyHeaderHeight'] + 'px',
      height:
        diffTop(
          v.endTime,
          v.startTime,
          weekTableDisplayFlg
            ? store.getters['timeTable/get'].unitHeightForWeekTable
            : store.getters['display/getData'].timeTableUnitHeight
        ) + 'px'
    }
  })
}

export const getWorkTimeStyles = (
  date,
  tableStartTime,
  weekTableDisplayFlg = false
) => {
  const specialOpenTime = store.getters['specialOpenTimes/getData'].find(
    v => v.date === date
  )
  if (specialOpenTime) {
    const workTimeArray = createSpecialWorkTimeArray(specialOpenTime, date)
    return mapStyles(workTimeArray, tableStartTime, weekTableDisplayFlg)
  } else {
    const workTimeArray = createWorkTimeArray(date)
    return mapStyles(workTimeArray, tableStartTime, weekTableDisplayFlg)
  }
}

export const createSpecialWorkTimeArray = (specialOpenTime, date) => {
  const reservationAvailablePattern = store.getters[
    'reservationAvailablePatterns/getDataById'
  ](specialOpenTime.reservationAvailablePatternId)
  if (reservationAvailablePattern.baseTimeFlg === 1) {
    const openTime = getOpenTime(date)
    return openTime.lunchOpenFlg === 1
      ? [{ id: 1, startTime: openTime.start, endTime: openTime.end }]
      : [
          {
            id: 1,
            startTime: openTime.start,
            endTime: openTime.lunchStart
          },
          { id: 2, startTime: openTime.lunchEnd, endTime: openTime.end }
        ]
  } else if (reservationAvailablePattern.closedDayFlg === 1) {
    return []
  } else {
    return reservationAvailablePattern.times.map((v, i) => {
      return {
        id: i + 1,
        startTime: v.substring(0, 4),
        endTime: v.substring(4, 8)
      }
    })
  }
}

export const createWorkTimeArray = date => {
  const openTime = getOpenTime(date)
  return openTime.closeFlg === 1
    ? []
    : openTime.lunchOpenFlg === 1
    ? [{ id: 1, startTime: openTime.start, endTime: openTime.end }]
    : [
        { id: 1, startTime: openTime.start, endTime: openTime.lunchStart },
        { id: 2, startTime: openTime.lunchEnd, endTime: openTime.end }
      ]
}

export const getOffTimeStyles = (
  reservationColumn,
  date,
  tableStartTime,
  weekTableDisplayFlg = false
) => {
  if (reservationColumn.staffId !== 0) {
    const offTimeArray = createOffTimeArray(reservationColumn.staffId, date)
    return mapStyles(offTimeArray, tableStartTime, weekTableDisplayFlg)
  } else return {}
}

export const createOffTimeArray = (staffId, date) => {
  const openTime = getOpenTime(date)
  const yearMonth = date.slice(0, 6)
  const day = Number(date.slice(6, 8))
  const staffWorkingDate = store.getters['staffWorkingDates/getData'].find(
    v => v.staffId === staffId && v.yearMonth === yearMonth
  )
  if (staffWorkingDate) {
    const shiftId = staffWorkingDate.shiftIds[day - 1]
    const shift = store.getters['shifts/getDataById'](shiftId)
    return shift.startTime === '0000' && shift.endTime === '0000'
      ? [{ id: 1, startTime: openTime.start, endTime: openTime.end }]
      : openTime.start < shift.startTime && shift.endTime < openTime.end
      ? [
          { id: 1, startTime: openTime.start, endTime: shift.startTime },
          { id: 2, startTime: shift.endTime, endTime: openTime.end }
        ]
      : openTime.start < shift.startTime
      ? [{ id: 1, startTime: openTime.start, endTime: shift.startTime }]
      : shift.endTime < openTime.end
      ? [{ id: 1, startTime: shift.endTime, endTime: openTime.end }]
      : []
  } else return []
}
