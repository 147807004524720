<template>
  <div class="time-table-header">
    <div class="top">
      <time-table-header-change-date
        :weekTableDisplayFlg="weekTableDisplayFlg"
      />
      <base-button-register
        v-if="lookOnlyFlg === 0 && !weekTableDisplayFlg"
        :text="'予約追加'"
        :styles="{ width: '110px' }"
        @click="openReservation"
      />
    </div>
    <div v-if="!weekTableDisplayFlg" class="bottom">
      <div class="left">
        <div class="reservation-count" data-test="reservation-count">
          予約数：{{ reservationLen }}件
        </div>
        <base-button-small-orange
          v-if="clearText !== ''"
          class="request-clear"
          @click="requestClear"
          ><template>{{ clearText }}</template>
        </base-button-small-orange>
      </div>
      <div class="right">
        <time-table-header-search-box class="search-box" />
      </div>
    </div>
    <div class="space" />
    <transition name="fade">
      <div class="overlay" v-if="flashMsgFlg">
        <div class="flash">
          <div class="text">{{ flashMsg }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import TimeTableHeaderChangeDate from '@/components/parts/organisms/TimeTableHeaderChangeDate'
import TimeTableHeaderSearchBox from '@/components/parts/organisms/TimeTableHeaderSearchBox'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import { mapGetters } from 'vuex'

export default {
  name: 'TimeTableHeader',
  components: {
    TimeTableHeaderChangeDate,
    BaseButtonSmallOrange,
    BaseButtonRegister,
    TimeTableHeaderSearchBox
  },
  props: {
    flashMsgFlg: { type: Boolean, default: false },
    flashMsg: { typs: String, default: false },
    weekTableDisplayFlg: { type: Boolean, default: false }
  },
  data() {
    return {
      cover: false
    }
  },
  computed: {
    ...mapGetters({
      staffReservationColumns: 'staffReservationColumns/getData',
      reservations: 'reservations/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    clearText() {
      return this.reservationRequestId !== 0
        ? '申請入力クリア'
        : this.initialReservation !== null && this.$route.params.fromPage
        ? this.$route.params.fromPage === 'owner'
          ? '飼主入力クリア'
          : '患者入力クリア'
        : ''
    },
    selectDate() {
      return this.$store.getters['timeTable/get'].date
    },
    displayReservationColumnIds() {
      return this.staffReservationColumns
        .filter(v => {
          return v.showFlg === 1
        })
        .map(v => v.reservationColumnId)
    },
    reservationLen() {
      let totalReservationLen = 0
      this.displayReservationColumnIds.forEach(reservationColumnId => {
        const reservationLen = this.reservations.filter(v => {
          return (
            v.reservationColumnId === reservationColumnId &&
            v.date === this.selectDate &&
            v.cancelFlg === 0 &&
            v.delFlg === 0
          )
        }).length
        totalReservationLen += reservationLen
      })
      return totalReservationLen
    },
    reservationRequestId() {
      return this.$store.getters['timeTable/get'].reservationRequestId
    },
    initialReservation() {
      return this.$store.getters['timeTable/get'].initialReservation
    }
  },
  methods: {
    openReservation() {
      this.$store.dispatch('timeTable/showPopup')
    },
    requestClear() {
      this.$store.dispatch('timeTable/deleteInitials')
      this.$emit('request-clear')
    }
  }
}
</script>

<style lang="scss" scoped>
.time-table-header {
  width: 100%;
  min-width: 651px;
  border-bottom: 1px solid #{$light-grey};
  flex-shrink: 0;
  flex-basis: auto;
  > .top {
    padding: 16px 20px 0 20px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > .bottom {
    padding: 10px 20px 0 24px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .left {
      display: flex;
      align-items: center;
      > .reservation-count {
        font-size: 23px;
      }
      > .request-clear {
        margin-left: 20px;
      }
    }
  }
  > .space {
    height: 28px;
  }
}
.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding-top: 130px;
  > .flash {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background: black;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    > .text {
      color: white;
      white-space: pre-wrap;
    }
  }
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s;
}
</style>
