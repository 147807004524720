<template>
  <div class="base-main-left-menu-item" data-e2e="base-main-left-menu-item">
    <router-link
      class="menu-link"
      :class="{ wide: wide, selected: isSelected }"
      :to="item.link"
    >
      <img class="icon" :src="src" width="28px" height="29px" alt="icon" />
      <div v-if="wide" class="text">{{ item.value }}</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BaseMainLeftMenuItem',
  props: {
    item: {
      type: Object,
      validator: function(v) {
        return (
          typeof v.value === 'string' &&
          typeof v.link === 'string' &&
          typeof v.icon === 'string'
        )
      }
    },
    wide: { type: Boolean },
    isSelected: { type: Boolean }
  },
  computed: {
    src() {
      return require('@/assets/images/' + this.item.icon)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-main-left-menu-item {
  text-align: left;
  font-size: 15px;
  > .menu-link {
    height: 60px;
    display: flex;
    align-items: center;
    &.wide {
      justify-content: left;
      padding-left: 20px;
    }
    &:not(.wide) {
      justify-content: center;
    }
    &.selected {
      background-color: #{$gray_ea};
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    > .text {
      margin-left: 10px;
    }
  }
}
</style>
