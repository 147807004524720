<template>
  <div class="time-table-body" ref="timeTableWidth" @scroll="handleScroll">
    <div class="blank-cell">
      <base-button-small-white
        :styles="{ width: '70px' }"
        @click="$emit('open-week-table')"
        >{{ '週間表示' }}</base-button-small-white
      >
    </div>
    <time-table-indicator
      class="float-indicator"
      :startTime="startTime"
      :endTime="endTime"
      :dragModeFlg="dragModeFlg"
      :dragReservationObj="dragReservationObj"
    />
    <div class="wrapper">
      <div class="left-line-wrapper">
        <div class="left-line-spacer"></div>
        <div class="left-line-header" :style="leftLineHeaderStyles"></div>
        <div
          class="left-line"
          :style="rightStyles"
          v-for="(label, index) in indicateLabelsShort"
          :key="index"
        ></div>
      </div>
      <div class="reservation-columns" ref="reservationColumns">
        <draggable
          :sort="false"
          handle=".reservation-item.no-karte"
          class="draggable"
        >
          <reservation-column
            v-model="scrollTop"
            v-for="reservationColumn in filteredReservationColumns"
            :key="reservationColumn.id"
            :reservationColumn="reservationColumn"
            :startTime="startTime"
            :endTime="endTime"
            :dayReservations="dayReservations"
            :dragModeFlg="dragModeFlg"
            :dragReservationObj="dragReservationObj"
            :workTimeStyles="workTimeStyles"
            @setWidth="setWidth"
            @start="dragStartReservation"
            @drag-reservation="dragReservation"
            @end="dropReservation"
            @update-initial-reservation="
              data => $emit('update-initial-reservation', data)
            "
          />
        </draggable>
      </div>
      <div class="right-line-wrapper">
        <div class="right-line-spacer"></div>
        <div class="right-line-header" :style="rightLineHeaderStyles"></div>
        <div
          class="right-line"
          :style="rightStyles"
          v-for="(label, index) in indicateLabelsShort"
          :key="index"
        ></div>
        <div class="right-line-footer"></div>
      </div>
    </div>
    <base-now-time-line
      v-if="showTimeLine"
      :columnLen="filteredReservationColumns.length"
      :startTime="startTime"
      :columnWidths="columnWidths"
    />
  </div>
</template>

<script>
import moment from 'moment'
import ReservationColumn from '@/components/parts/organisms/ReservationColumn'
import TimeTableIndicator from '@/components/parts/organisms/TimeTableIndicator'
import BaseNowTimeLine from '@/components/parts/atoms/BaseNowTimeLine'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import {
  indicateLabels,
  diffTop,
  getTimeTableStartEnd,
  getFilteredReservationColumns,
  getWorkTimeStyles
} from '@/utils/time_table'
import Draggable from 'vuedraggable'
import { isPcModeOnIpad } from '@/utils/get_device'
export default {
  name: 'TimeTableBody',
  components: {
    TimeTableIndicator,
    ReservationColumn,
    BaseNowTimeLine,
    BaseButtonSmallWhite,
    Draggable
  },
  props: {
    initialReservationProp: { type: Object, default: null }
  },
  data() {
    return {
      floatTime: false,
      styles: { left: '0px' },
      scrollTop: 0,
      scrollLeft: 0,
      columnWidths: {},
      dragModeFlg: false,
      dragReservationObj: {
        reservation: { id: 0 },
        reservationColumnId: 0,
        startTime: '',
        endTime: ''
      },
      touchY: 0,
      touchX: 0,
      tableHeight: 0,
      tableWidth: 0,
      edgeAreaHeightWidth: 40
    }
  },
  computed: {
    leftMenuWideFlg() {
      return this.$store.getters['petorelu/get'].leftWideFlg
    },
    rightMenuWideFlg() {
      return this.$store.getters['petorelu/get'].rightWideFlg
    },
    reservationColumnLength() {
      return this.$store.getters['staffReservationColumns/getData'].filter(
        v => v.showFlg === 1
      ).length
    },
    dayReservations() {
      return this.$store.getters['reservations/getData'].filter(
        v =>
          v.date === this.$store.getters['timeTable/get'].date &&
          v.cancelFlg === 0 &&
          v.delFlg === 0
      )
    },
    selectedColumnReservations() {
      return this.dayReservations.filter(reservation =>
        this.filteredReservationColumns.some(
          reservationColumn =>
            reservationColumn.id === reservation.reservationColumnId
        )
      )
    },
    startEndTime() {
      return getTimeTableStartEnd(
        this.$store.getters['timeTable/get'].date,
        this.selectedColumnReservations
      )
    },
    startTime() {
      const initialReservation = this.$store.getters['timeTable/get']
        .initialReservation
      let startTime = this.startEndTime.startTime
      if (initialReservation) {
        startTime = Math.min(initialReservation.startTime, startTime)
      }
      startTime =
        String(startTime)
          .padStart(4, 0)
          .substring(0, 2) + '00'
      return startTime
    },
    endTime() {
      const initialReservation = this.$store.getters['timeTable/get']
        .initialReservation
      let endTime = this.startEndTime.endTime
      if (initialReservation) {
        endTime = Math.max(initialReservation.endTime, endTime)
      }
      endTime = String(endTime).padStart(4, 0)
      if (endTime.slice(2) !== '00') {
        const hour = Number(endTime.slice(0, 2)) + 1
        endTime = ('0000' + hour + '00').slice(-4)
      }
      return endTime
    },
    rightStyles() {
      return {
        height:
          this.$store.getters['display/getData'].timeTableUnitHeight + 'px'
      }
    },
    leftLineHeaderStyles() {
      return {
        height:
          this.$store.getters['display/getColumnSquareHeaderHeight'] + 'px'
      }
    },
    rightLineHeaderStyles() {
      return {
        height:
          this.$store.getters['display/getColumnSquareHeaderHeight'] + 'px'
      }
    },
    indicateLabelsShort() {
      const labels = indicateLabels(this.startTime, this.endTime)
      return labels.splice(0, labels.length - 1)
    },
    reservationColumns() {
      return this.$store.getters['reservationColumns/getData']
    },
    staffReservationColumns() {
      return this.$store.getters['staffReservationColumns/getData']
    },
    filteredReservationColumns() {
      return getFilteredReservationColumns(
        this.reservationColumns,
        this.staffReservationColumns
      )
    },
    showTimeLine() {
      return (
        this.$store.getters['timeTable/get'].date ===
          moment().format('YYYYMMDD') &&
        moment().format('HHmm') >= this.startTime &&
        moment().format('HHmm') <= this.endTime
      )
    },
    workTimeStyles() {
      return getWorkTimeStyles(
        this.$store.getters['timeTable/get'].date,
        this.startTime
      )
    }
  },
  watch: {
    reservationColumnLength: function(newValue, oldValue) {
      this.setReservationColumnWidthParts()
    },
    leftMenuWideFlg: function() {
      this.$nextTick(() => {
        this.setReservationColumnWidthParts()
      })
    },
    rightMenuWideFlg: function() {
      this.$nextTick(() => {
        this.setReservationColumnWidthParts()
      })
    },
    scrollTop: function() {
      if (this.dragModeFlg && isPcModeOnIpad()) {
        if (this.touchY < this.edgeAreaHeightWidth) {
          this.$el.scrollBy(0, -10)
        } else if (this.tableHeight - this.touchY < this.edgeAreaHeightWidth) {
          this.$el.scrollBy(0, 10)
        }
      }
    },
    scrollLeft: function() {
      if (this.dragModeFlg && isPcModeOnIpad()) {
        if (this.touchX < this.edgeAreaHeightWidth) {
          this.$el.scrollBy(-10, 0)
        } else if (this.tableWidth - this.touchX < this.edgeAreaHeightWidth) {
          this.$el.scrollBy(10, 0)
        }
      }
    }
  },
  mounted() {
    this.initialScroll()
    this.setReservationColumnWidthParts()
    window.addEventListener('resize', this.setReservationColumnWidthParts)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setReservationColumnWidthParts)
  },
  methods: {
    setReservationColumnWidthParts() {
      const extraWidth = 125
      const timeTableWidth = this.$refs.timeTableWidth.offsetWidth - extraWidth
      this.$store.dispatch('timeTable/setReservationColumnWidthParts', {
        timeTableWidth,
        reservationColumnLength: this.reservationColumnLength
      })
    },
    setWidth(obj) {
      this.$set(this.columnWidths, obj.id, obj.width)
    },
    initialScroll() {
      const unitHeight = this.$store.getters['display/getData']
        .timeTableUnitHeight
      const initialReservation = this.$store.getters['timeTable/get']
        .initialReservation
      const scrollInfo = this.$route.params.scrollInfo
      let scrollX = 0
      let scrollY = 0
      const reservationColumns = this.filteredReservationColumns
      if (scrollInfo !== undefined) {
        for (let i = 0; i < reservationColumns.length; i++) {
          if (reservationColumns[i].id === scrollInfo.reservationColumnId) break
          scrollX += this.columnWidths[reservationColumns[i].id]
        }
        scrollY = diffTop(
          scrollInfo.startTime,
          this.startEndTime.startTime,
          unitHeight
        )
      } else if (
        initialReservation !== null ||
        this.initialReservationProp !== null
      ) {
        const reservation = initialReservation
          ? initialReservation
          : this.initialReservationProp
        for (let i = 0; i < reservationColumns.length; i++) {
          if (
            reservationColumns[i].id === reservation.reservationColumnId ||
            reservation.ownerPatientPageFlg === true
          ) {
            break
          }
          scrollX += this.columnWidths[reservationColumns[i].id]
        }
        scrollY = diffTop(
          reservation.startTime,
          this.startEndTime.startTime,
          unitHeight
        )
      } else {
        scrollY = diffTop(
          moment().format('HHmm'),
          this.startEndTime.startTime,
          unitHeight
        )
      }
      this.$el.scrollTo(scrollX, scrollY)
    },
    handleScroll(e) {
      this.scrollTop = e.target.scrollTop
      this.scrollLeft = e.target.scrollLeft
    },
    dragStartReservation(dragReservation) {
      this.dragModeFlg = true
      this.dragReservationObj = {
        reservation: dragReservation,
        reservationColumnId: 0,
        startTime: '',
        endTime: ''
      }
    },
    dragReservation(dragReservationObj, e) {
      // ↓ドラッグ中、ドラッグ位置の予約列・時間をオレンジ色で表示する動作が重くならないよう、
      //  ドラッグ位置の予約列・予約開始時間・予約終了時間の値が変わった時だけ、data: dragReservationObjの値を変更しております
      if (
        this.dragReservationObj.reservationColumnId !==
          dragReservationObj.reservationColumnId ||
        this.dragReservationObj.startTime !== dragReservationObj.startTime ||
        this.dragReservationObj.endTime !== dragReservationObj.endTime
      ) {
        this.dragReservationObj = dragReservationObj
      }
      this.autoScrollOnDrag(e)
    },
    dropReservation() {
      this.dragModeFlg = false
      this.dragReservationObj = {
        reservation: { id: 0 },
        reservationColumnId: 0,
        startTime: '',
        endTime: ''
      }
      this.touchY = 0
      this.touchX = 0
      this.tableHeight = 0
      this.tableWidth = 0
    },
    autoScrollOnDrag(e) {
      if (e !== undefined && isPcModeOnIpad()) {
        this.touchY =
          e.changedTouches[0].clientY -
          this.$refs.timeTableWidth.getBoundingClientRect().top
        this.touchX =
          e.changedTouches[0].clientX -
          this.$refs.timeTableWidth.getBoundingClientRect().left
        // ↓ getBoundingClientRect().heightだと各ブラウザで値が異なり、safariだと下端にいる場合のスクロール処理に行かないため書き方を変更
        this.tableHeight =
          window.innerHeight -
          this.$refs.timeTableWidth.getBoundingClientRect().top
        this.tableWidth = this.$refs.timeTableWidth.getBoundingClientRect().width
        if (this.touchY < this.edgeAreaHeightWidth) {
          this.$el.scrollBy(0, -10)
        } else if (this.tableHeight - this.touchY < this.edgeAreaHeightWidth) {
          this.$el.scrollBy(0, 10)
        } else if (this.touchX < this.edgeAreaHeightWidth) {
          this.$el.scrollBy(-10, 0)
        } else if (this.tableWidth - this.touchX < this.edgeAreaHeightWidth) {
          this.$el.scrollBy(10, 0)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.time-table-body {
  display: flex;
  overflow-y: auto;
  position: relative;
  > .blank-cell {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    left: 0px;
    z-index: 20;
    width: 74px;
    height: 26px;
    background-color: #{$white};
  }
  > .float-indicator {
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    z-index: 10;
    pointer-events: none;
    margin-left: -74px; // 週間表示ボタンの横幅の分、左にずらす
  }
  > .wrapper {
    display: flex;
    > .reservation-columns {
      > .draggable {
        display: flex;
      }
    }
    > .left-line-wrapper {
      width: 26px;
      > .left-line-spacer {
        height: 26px;
      }
      > .left-line-header {
        border-bottom: solid 1px #{$light-grey};
      }
      > .left-line {
        box-sizing: border-box;
        border-bottom: solid 1px #{$light-grey};
      }
    }
    > .right-line-wrapper {
      > .right-line-spacer {
        width: 30px;
        height: 26px;
      }
      > .right-line-header {
        width: 30px;
        border-bottom: solid 1px #{$light-grey};
      }
      > .right-line {
        width: 30px;
        border-bottom: solid 1px #{$light-grey};
        box-sizing: border-box;
      }
      > .right-line-footer {
        width: 30px;
        height: 44.5px;
      }
    }
  }
}
</style>
