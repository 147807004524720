<template>
  <div class="reservation-status">
    <div class="wrap">
      <div class="check-list">
        <div
          class="check-list-item"
          v-for="item in checkList"
          :key="item.waitingStatusId"
        >
          <base-check-box
            class="checkbox"
            :color="item.color"
            :labelText="item.label"
            :labelStyle="{ marginLeft: '5px' }"
            :isChecked="item.checked"
            v-model="item.checked"
          />
          <div class="count">
            <span>{{ `${toCount(item.waitingStatusId)} 件` }}</span>
          </div>
        </div>
      </div>
      <div class="table">
        <div class="table-head">
          <div class="cell"><div class="text coming">来院</div></div>
          <div class="cell"><div class="text owner">飼主</div></div>
          <div class="cell"><div class="text patient">患者</div></div>
          <div class="cell"><div class="text status">状況</div></div>
        </div>
        <transition-group name="list" tag="div" class="table-body">
          <div
            class="row"
            :class="{ linked: r.linkedContentDetail.content }"
            :ref="'row'"
            v-for="r in displayListReservations"
            :key="r.id"
            :data-test="`row-${r.id}`"
            @click="goToLinkedContentDetail(r)"
            @mouseleave="unhighlightReservation"
            @mouseover="highlightReservation(r)"
          >
            <div class="cell">{{ toTime(r.receptionTime) }}</div>
            <div class="cell">
              <div class="text" :title="toOwnerName(r)">
                {{ toOwnerName(r) }}
              </div>
            </div>
            <div class="cell">
              <div class="text" :title="toPatientName(r)">
                {{ toPatientName(r) }}
              </div>
            </div>
            <div class="cell">
              <waiting-status-switch-button
                :isMouseDevice="isMouseDevice"
                :karteFlg="karteFlg"
                :lookOnlyFlg="lookOnlyFlg"
                :reservation="r"
                :updatingReservationId="updatingReservationId"
                :waitingStatuses="waitingStatuses"
                @click="update"
              />
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <announce-popup v-if="popupFlg" v-bind="popup" @close="popupFlg = false">{{
      popup.message
    }}</announce-popup>
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import ColorMethods from '@/components/mixins/ColorMethods'
import WaitingStatusSwitchButton from '@/components/parts/atoms/WaitingStatusSwitchButton'
import moment from 'moment'
import { canHavePayment } from '@/utils/medical_payment_helper'
import { getDevice } from '@/utils/get_device'
import { makePaymentDetail } from '@/utils/price_calculation'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationStatuses',

  components: {
    AnnouncePopup,
    BaseCheckBox,
    WaitingStatusSwitchButton
  },

  mixins: [ColorMethods],

  data() {
    return {
      checkList: [],
      isMouseDevice: true,
      popup: {
        buttons: ['閉じる'],
        message: '',
        title: '失敗',
        type: 'failure'
      },
      popupFlg: false,
      updatingReservationId: 0
    }
  },

  computed: {
    ...mapGetters({
      karteFlg: 'auth/karteFlg',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      ownerById: 'owners/getDataById',
      patientById: 'patients/getDataById',
      reservations: 'reservations/getData',
      waitingStatuses: 'waitingStatuses/getData'
    }),
    checkedWaitingStatusIds() {
      const checkedWaitingStatusIds = this.checkList
        .filter(v => v.checked)
        .map(v => v.waitingStatusId)
      return checkedWaitingStatusIds
    },
    displayListReservations() {
      const displayListReservations = this.listReservations
        .filter(v => this.hasCheckedStatus(v))
        .sort((a, b) => this.compareReservations(a, b))
        .map(v => {
          const linkedContentDetail = this.toLinkedContentDetail(v)
          return { ...v, linkedContentDetail }
        })
      return displayListReservations
    },
    listReservations() {
      return this.reservations.filter(v => this.isListable(v))
    }
  },

  created() {
    this.setCheckList()
  },

  mounted() {
    this.isMouseDevice = getDevice() === 'pc'
  },

  beforeDestroy() {
    this.$store.dispatch('timeTable/resetHighlightedReservation')
  },

  watch: {
    waitingStatuses: {
      deep: true,
      handler() {
        this.setCheckList()
      }
    }
  },

  methods: {
    compareReservations(a, b) {
      if (!a.receptionTime && !b.receptionTime) {
        return a.id - b.id
      } else if (a.receptionTime === '') {
        return -1
      } else if (b.receptionTime === '') {
        return 1
      } else {
        if (a.receptionTime === b.receptionTime) {
          return a.id - b.id
        } else if (a.receptionTime < b.receptionTime) {
          return -1
        } else {
          return 1
        }
      }
    },
    goToLinkedContentDetail(reservation) {
      const { linkedContentDetail } = reservation
      if (linkedContentDetail.content) {
        if (linkedContentDetail.content === 'reservation') {
          this.goToReservationPopup(reservation)
        } else {
          this.$router.push(linkedContentDetail.route)
        }
      }
    },
    goToReservationPopup(reservation) {
      this.$store.dispatch('timeTable/setInitialReservation', reservation)
      this.$store.dispatch('timeTable/changeDate', reservation.date)
      this.$store.dispatch('timeTable/showPopup')
    },
    hasCheckedStatus(reservation) {
      const has = this.checkedWaitingStatusIds.includes(
        reservation.waitingStatusId
      )
      return has
    },
    highlightReservation(reservation) {
      this.$store.dispatch('timeTable/setHighlightedReservation', reservation)
    },
    isListable(reservation) {
      const { cancelFlg, date, delFlg } = reservation
      const isActive = !cancelFlg && !delFlg
      const isToday = date === moment().format('YYYYMMDD')
      return isActive && isToday
    },
    setCheckList() {
      this.checkList = this.waitingStatuses
        .filter(v => v.menuDisplayableStatusFlg)
        .map(waitingStatus => {
          const itemBeforeReset = this.checkList.find(
            item => item.waitingStatusId === waitingStatus.id
          )
          return {
            checked: itemBeforeReset?.checked ?? true,
            color: waitingStatus.color,
            label: waitingStatus.name,
            waitingStatusId: waitingStatus.id
          }
        })
    },
    toCount(waitingStatusId) {
      const count = this.listReservations.filter(
        v => v.waitingStatusId === waitingStatusId
      ).length
      return count
    },
    toLinkedContentDetail(reservation) {
      const waitingStatus = this.waitingStatuses.find(
        v => reservation.waitingStatusId === v.id
      )
      const linkedContent = waitingStatus?.linkedContent ?? ''
      let content = ''
      let route = null
      if (linkedContent) {
        if (this.karteFlg) {
          if (linkedContent === 'reservation') {
            content = 'reservation'
          } else if (linkedContent === 'karte') {
            ;({ content, route } = this.toLinkedContentDetailKarte(reservation))
          } else if (linkedContent === 'payment') {
            ;({ content, route } = this.toLinkedContentDetailPayment(
              reservation
            ))
          }
        } else {
          if (linkedContent === 'reservation') {
            content = 'reservation'
          }
        }
      }
      return { content, route }
    },
    toLinkedContentDetailKarte(reservation) {
      let content = ''
      let route = null
      const canHaveMedicalRecord = reservation.patientId
      if (canHaveMedicalRecord) {
        content = 'karte'
        const medicalRecord = this.$store.getters[
          'medicalRecords/getDataByOriginalId'
        ](reservation.medicalRecordOriginalId)
        if (medicalRecord) {
          route = {
            name: 'medical-record-edit',
            params: {
              originalId: medicalRecord.originalId,
              ownerId: reservation.ownerId,
              patientId: reservation.patientId
            }
          }
        } else {
          route = {
            name: 'medical-record-new',
            params: {
              ownerId: reservation.ownerId,
              patientId: reservation.patientId,
              reservation: reservation
            }
          }
        }
      }
      return { content, route }
    },
    toLinkedContentDetailPayment(reservation) {
      let content = ''
      let route = null
      if (reservation.medicalRecordOriginalId) {
        const medicalPayments =
          this.$store.getters[
            'medicalPayments/getDataByMedicalRecordOriginalId'
          ](reservation.medicalRecordOriginalId) ?? []
        const medicalPayment = medicalPayments.find(v => v.delFlg === 0)
        if (canHavePayment(medicalPayment)) {
          content = 'payment'
          const paymentType = this.toPaymentType(medicalPayment)
          const params = {
            medicalPaymentOriginalId: medicalPayment.originalId,
            ownerId: reservation.ownerId,
            patientId: reservation.patientId
          }
          if (paymentType === '未会計') {
            route = { name: 'patient-payment-new', params }
          } else {
            route = { name: 'patient-payment-show', params }
          }
        }
      }
      return { content, route }
    },
    toOwnerName(reservation) {
      const { ownerId, ownerLastName, ownerFirstName } = reservation
      const owner = this.ownerById(ownerId)
      const name = owner
        ? `${owner.lastName} ${owner.firstName}`
        : ownerLastName && ownerFirstName
        ? `${ownerLastName} ${ownerFirstName}`
        : ownerLastName || ownerFirstName || ''
      return name
    },
    toPaymentType(medicalPayment) {
      const getMedicalPaymentHistory = this.$store.getters[
        'medicalPayments/getDataByOriginalIdIncludeDel'
      ]
      const getMedicalRecordByOriginalId = this.$store.getters[
        'medicalRecords/getDataByOriginalId'
      ]
      const getPaymentsByMedicalPaymentId = this.$store.getters[
        'payments/getDataByMedicalPaymentId'
      ]
      const { type } = makePaymentDetail(
        medicalPayment,
        getMedicalPaymentHistory,
        getMedicalRecordByOriginalId,
        getPaymentsByMedicalPaymentId
      )
      return type
    },
    toPatientName(reservation) {
      const patient = this.patientById(reservation.patientId)
      const name = patient?.name || reservation.patientName || ''
      return name
    },
    toTime(HHmm) {
      const time = HHmm ? moment(HHmm, 'HHmm').format('HH:mm') : ''
      return time
    },
    unhighlightReservation() {
      this.$store.dispatch('timeTable/resetHighlightedReservation')
    },
    async update(reservation) {
      const { id } = reservation
      this.updatingReservationId = id
      const result = await this.$store.dispatch('reservations/updateStatus', {
        id
      })
      if (result !== 'success') {
        let message = '待合状況の変更に失敗しました。'
        if (result === 'no data') {
          message = '対象の予約は既にキャンセルまたは削除されています。'
        } else if (result === 'server error') {
          message = '通信エラーが発生しました。'
        }
        this.popup.message = message
        this.popupFlg = true
      }
      this.updatingReservationId = 0
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin cell-text {
  flex: 1;
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reservation-status {
  width: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
  > .wrap {
    width: 100%;
    > .check-list {
      margin-top: 16px;
      padding-left: 10px;
      padding-right: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px 0;
      > .check-list-item {
        width: 100%;
        display: flex;
        gap: 0 20px;
        justify-content: space-between;
        align-items: center;
        > .checkbox {
          flex: 1;
        }
        > .count {
          font-size: 14px;
        }
      }
    }
    > .table {
      margin-top: 16px;
      box-sizing: border-box;
      display: grid;
      grid-template-rows: 28px auto;
      border-radius: 3px;
      > .table-head {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 50px repeat(2, 1fr) 90px;
        > .cell {
          box-sizing: border-box;
          line-height: 28px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          > .text {
            width: calc(100% - 10px);
            height: 26px;
            font-size: 13px;
            color: gray;
            border-bottom: solid 2px #{$light-grey};
          }
        }
      }
      > .table-body {
        display: grid;
        grid-template-rows: auto;
        > .row {
          box-sizing: border-box;
          height: 38px;
          display: grid;
          grid-template-columns: 50px repeat(2, 1fr) 90px;
          border-radius: 6px;
          transition: opacity 0.6s, transform 0.6s ease;
          &:hover {
            background-color: #{$very-light-grey};
            &.linked {
              cursor: pointer;
            }
          }
          > .cell {
            box-sizing: border-box;
            padding: 0 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            text-align: center;
            > .text {
              @include cell-text;
            }
          }
        }
      }
    }
  }
}

// transition
.list-enter-active,
.list-leave-active {
  transition: opacity 0.6s, transform 0.6s ease;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(200px);
}
</style>
