<template>
  <div
    class="initial-reservation-item"
    :style="styles"
    @click="displayReservation"
    data-e2e="initial-reservation-item"
  >
    〇
  </div>
</template>
<script>
export default {
  name: 'InitialReservationItem',
  props: {
    styles: { type: Object }
  },
  methods: {
    displayReservation() {
      this.$emit('click')
    }
  }
}
</script>
<style lang="scss" scoped>
.initial-reservation-item {
  color: #{$white};
  font-size: 12px;
  text-align: left;
  position: absolute;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 2px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
</style>
