<template>
  <div
    class="reservation-column"
    :data-column-id="reservationColumn.id"
    @dragover="dragOverFireFox"
  >
    <div class="column-name" :style="nameStyles">
      {{ reservationColumn.name }}
    </div>
    <div class="column-square-header" :style="columnSquareHeaderStyles"></div>
    <div
      class="column-square"
      v-for="label in indicateLabels"
      :key="label"
      :data-indicate-label="label"
      :style="styles"
      @click="openNewReservation($event, label)"
      data-e2e="column-square"
    ></div>
    <div class="column-square-footer" :style="columnSquareFooterStyles"></div>
    <draggable
      :list="reservations"
      :sort="true"
      :class="`draggable-reservation-item-${reservationColumn.id}`"
      handle=".no-karte"
      @start="dragStartReservation"
      @end="dropReservation"
    >
      <reservation-item
        v-for="(reservation, i) in reservations"
        :reservationDatum="reservation"
        :key="reservation.id"
        :data-reservation-item="reservation.id"
        :reservationStyles="reservationStyles[i]"
        :moveEndTimeObj="
          reservation.id === moveEndTimeObj.reservation.id
            ? moveEndTimeObj
            : null
        "
        :class="{ 'no-karte': reservation.medicalRecordOriginalId === 0 }"
        @drag-reservation="dragReservation"
        @click="openReservation(reservation)"
        @start-move-end-time="startMoveEndTime($event, reservation)"
        @move-end-time="moveEndTime($event, reservation)"
        @open-with-changed-end-time="openWithChangedEndTime(reservation)"
      />
    </draggable>
    <initial-reservation-item
      v-if="initialReservationItemStyle.showFlg"
      :styles="initialReservationItemStyle"
      @click="makeInitialReservation"
    />
    <div
      class="request-block"
      v-for="requestBlockStyle in requestBlockStyles"
      :key="requestBlockStyle.id + '-request'"
      :style="requestBlockStyle"
    ></div>
    <div class="outside-working-time" :style="outsideWorkingTimeStyles"></div>
    <div
      class="working-time"
      v-for="workTimeStyle in workTimeStyles"
      :key="workTimeStyle.id + '-work'"
      :style="workTimeStyle"
      data-test="working-time"
    ></div>
    <div
      class="off-time"
      v-for="offTimeStyle in offTimeStyles"
      :key="offTimeStyle.id + '-off'"
      :style="offTimeStyle"
      data-test="off-time"
    ></div>
    <announce-popup
      v-if="alertFlg"
      @cancel="alertFlg = false"
      @decision="makeDifferentNewReservation"
      :title="'注意'"
      :buttons="['作成しない', '作成する']"
      :type="'alert'"
      >申請された日時または予約列と異なりますが予約を作成しますか？</announce-popup
    >
  </div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import {
  indicateLabels,
  hourUnit,
  diffTop,
  getOffTimeStyles
} from '@/utils/time_table'
import ReservationItem from '@/components/parts/organisms/ReservationItem'
import InitialReservationItem from '@/components/parts/organisms/InitialReservationItem'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import Draggable from 'vuedraggable'
import ColorMethods from '@/components/mixins/ColorMethods'
import { mapGetters } from 'vuex'
export default {
  name: 'ReservationColumn',
  components: {
    ReservationItem,
    InitialReservationItem,
    AnnouncePopup,
    Draggable
  },
  mixins: [ColorMethods],
  props: {
    startTime: { type: String },
    endTime: { type: String },
    reservationColumn: { type: Object },
    dayReservations: { type: Array },
    value: { type: Number },
    dragModeFlg: { type: Boolean, default: false },
    dragReservationObj: {
      type: Object,
      default() {
        return {
          reservation: { id: 0 },
          reservationColumnId: 0,
          startTime: '',
          endTime: ''
        }
      }
    },
    workTimeStyles: { type: Array }
  },
  data() {
    return {
      leftWidthDict: {},
      columnWidth: 0,
      initialReservationItemStyle: { showFlg: false, date: '' },
      alertFlg: false,
      tmpInitialReservation: {},
      moveEndTimeModeFlg: false,
      startTimeY: 0,
      moveEndTimeObj: { reservation: { id: 0 }, afterEndTime: '' },
      moveEndTimeReservationId: 0,
      initialScrollTop: 0,
      clientY: 0
    }
  },
  computed: {
    indicateLabels() {
      return indicateLabels(this.startTime, this.endTime).slice(0, -1)
    },
    initialReservation() {
      return this.timeTable.initialReservation
    },
    reservations() {
      return this.dayReservations
        .filter(v => {
          return v.reservationColumnId === this.reservationColumn.id
        })
        .map(v => {
          // 予約終了時間のドラッグによる変更中に毎回値をvuex/stateにセットする方法だと動作が重くなるので、
          // 一時的な予約終了時間の値をdataとvuex/stateの2つに保管しております
          return v.id === this.timeTable.moveEndTimeObj.reservation.id &&
            this.timeTable.moveEndTimeObj.afterEndTime.length !== 0
            ? { ...v, endTime: this.timeTable.moveEndTimeObj.afterEndTime }
            : v
        })
    },
    reservationStyles() {
      return this.reservations.map(reservation => {
        const top = diffTop(
          reservation.startTime,
          this.startTime,
          this.unitHeight
        )
        const leftWidth = this.leftWidthDict[reservation.id]
        const reservationPurposeColor = this.getReservationPurpose(
          reservation.reservationPurposeId
        )?.color
        const staffColor = this.$store.getters['staffs/getDataById'](
          reservation.staffId
        )?.color
        const colors = {
          reservationColumn: this.reservationColumn.color,
          reservationPurpose: reservationPurposeColor,
          staff: staffColor
        }
        const backgroundColor =
          colors[this.display.colorOrder[0]] ||
          colors[this.display.colorOrder[1]] ||
          colors[this.display.colorOrder[2]]
        const styles = {
          top:
            top +
            this.$store.getters['display/getTimetableBodyHeaderHeight'] +
            'px',
          left: leftWidth.left + 'px',
          width: leftWidth.width - 2 + 'px',
          backgroundColor,
          color: this.mixinAdaptTextColor(backgroundColor)
        }
        if (this.dragModeFlg) {
          styles.pointerEvents = 'none'
          styles.opacity = '0.4'
        } else if (
          this.moveEndTimeModeFlg &&
          this.moveEndTimeReservationId !== reservation.id
        ) {
          styles.pointerEvents = 'none'
          styles.opacity = '0.4'
        } else if (
          this.$store.getters['petorelu/get'].browser.includes('firefox') &&
          this.moveEndTimeModeFlg &&
          this.moveEndTimeReservationId === reservation.id
        ) {
          styles.pointerEvents = 'none'
        }
        return styles
      })
    },
    reservationRequestsSuitThisColumn() {
      return this.$store.getters['reservationRequests/getData']
        .filter(v => {
          const purpose = this.getReservationPurpose(v.reservationPurposeId)
          const requestPurposeColumns = purpose.reservationColumnsIds
          return (
            v.date === this.date &&
            v.reservationId === 0 &&
            v.delFlg === 0 &&
            requestPurposeColumns.includes(this.reservationColumn.id)
          )
        })
        .map(v => {
          const purposeTime = this.getReservationPurpose(v.reservationPurposeId)
            .time
          const endTime = moment(v.startTime, 'HHmm')
            .add(purposeTime, 'm')
            .format('HHmm')
          return { ...v, endTime }
        })
    },
    requestBlockStyles() {
      return this.reservationRequestsSuitThisColumn.map(v => {
        const top = diffTop(v.startTime, this.startTime, this.unitHeight)
        return {
          id: v.id,
          top:
            top +
            this.$store.getters['display/getTimetableBodyHeaderHeight'] +
            'px',
          height: diffTop(v.endTime, v.startTime, this.unitHeight) + 'px'
        }
      })
    },
    offTimeStyles() {
      return getOffTimeStyles(
        this.reservationColumn,
        this.timeTable.date,
        this.startTime
      )
    },
    styles() {
      return { height: this.unitHeight + 'px', width: this.columnWidth + 'px' }
    },
    nameStyles() {
      return this.dragModeFlg &&
        this.dragReservationObj.reservationColumnId ===
          this.reservationColumn.id
        ? {
            fontWeight: 'bold',
            color: '#ef6c00' // $pumpkin
          }
        : {}
    },
    columnSquareHeaderStyles() {
      return {
        height:
          this.$store.getters['display/getColumnSquareHeaderHeight'] + 'px'
      }
    },
    columnSquareFooterStyles() {
      return {
        height:
          this.$store.getters['display/getColumnSquareHeaderHeight'] + 'px'
      }
    },
    outsideWorkingTimeStyles() {
      // column-name + column-square-header
      const topNum =
        26 + this.$store.getters['display/getColumnSquareHeaderHeight']
      // column-name + column-square-header + column-square-footer
      const heitghtNum =
        26 + this.$store.getters['display/getColumnSquareHeaderHeight'] * 2
      const retv = {
        top: topNum + 'px',
        height: 'calc(100% - ' + heitghtNum + 'px)'
      }
      return retv
    },
    ...mapGetters({
      timeTable: 'timeTable/get',
      display: 'display/getData',
      getReservationPurpose: 'reservationPurposes/getDataById'
    }),
    unitHeight() {
      return this.display.timeTableUnitHeight
    },
    unitWidth() {
      return this.timeTable.unitWidth
    },
    timeTableWidth() {
      return this.timeTable.timeTableWidth
    },
    date() {
      const date = this.timeTable.date
      this.initialReservationItemStyle.date !== date
        ? this.$set(this.initialReservationItemStyle, 'showFlg', false)
        : this.$set(this.initialReservationItemStyle, 'showFlg', true)
      return date
    }
  },
  watch: {
    reservations: {
      immediate: true,
      deep: true,
      handler: function() {
        this.leftWidthDict = this.calcReservationLeftWidth()
      }
    },
    initialReservation: {
      immediate: true,
      deep: false,
      handler: function() {
        this.initialReservationShow()
      }
    },
    unitWidth: function() {
      this.leftWidthDict = this.calcReservationLeftWidth()
    },
    timeTableWidth: function() {
      this.leftWidthDict = this.calcReservationLeftWidth()
    }
  },
  mounted() {
    this.calcReservationLeftWidth()
    this.initialReservationShow()
  },
  methods: {
    isOverlap(res1, res2) {
      return (
        (res1.startTime <= res2.startTime && res1.endTime > res2.startTime) ||
        (res2.startTime <= res1.startTime && res2.endTime > res1.startTime)
      )
    },
    makeInitialReservation() {
      this.$store.dispatch('timeTable/showPopup')
    },
    //ある予約時間範囲で他と重ならない予約アイテムの left, width を得る
    initialReservationShow() {
      const initialReservation = this.timeTable.initialReservation
      if (
        initialReservation?.reservationColumnId === this.reservationColumn.id &&
        initialReservation?.date === this.timeTable.date &&
        initialReservation?.id === 0
      ) {
        const top = diffTop(
          initialReservation.startTime,
          this.startTime,
          this.unitHeight
        )
        const itemH = diffTop(
          initialReservation.endTime,
          initialReservation.startTime,
          this.unitHeight
        )
        this.initialReservationItemStyle = {
          showFlg: true,
          date: initialReservation.date,
          // left: this.timeTable.availableWidth + 'px',
          width: this.timeTable.blankWidth - 2 + 'px',
          top:
            top +
            this.$store.getters['display/getTimetableBodyHeaderHeight'] +
            'px',
          height: itemH - 1 + 'px',
          right: '2px',
          backgroundColor: this.reservationColumn.color
        }
      } else {
        this.initialReservationItemStyle = { showFlg: false }
      }
    },
    openReservation(reservation) {
      const initialReservation = this.$store.getters[
        'reservations/getDataById'
      ](reservation.id)
      this.$store.dispatch('timeTable/setInitialReservation', {
        ...initialReservation
      })
      this.$store.dispatch('timeTable/showPopup')
    },
    openNewReservation(event, label) {
      const initialReservation = _.cloneDeep(this.timeTable.initialReservation)
      const columnHeight = this.display.timeTableUnitHeight
      const timeUnit = this.$store.getters['reservationSetting/getData']
        .reservationTimeUnit
      const clickMinute =
        parseInt(((event.offsetY / columnHeight) * 60) / timeUnit) * timeUnit
      const startTime = label.substring(0, 2) + ('00' + clickMinute).slice(-2)
      let endTime = ''
      const requestId = this.timeTable.reservationRequestId
      const date = this.timeTable.date
      if (initialReservation !== null && requestId !== 0) {
        this.setInitialReservation(initialReservation, startTime)
        if (
          initialReservation.startTime !== startTime ||
          initialReservation.date !== date ||
          initialReservation.reservationColumnId !== this.reservationColumn.id
        ) {
          this.alertFlg = true
        } else {
          this.$store.dispatch('timeTable/showPopup')
        }
      } else {
        ////`2020-01-01 ${startTime.slice(0, 2)}:${startTime.slice(2)}:00`
        endTime = moment(startTime, 'HHmm')
          .add(timeUnit, 'minutes')
          .format('HHmm')
        this.$emit('update-initial-reservation', {
          ...initialReservation,
          reservationColumnId: this.reservationColumn.id,
          startTime,
          endTime
        })
        this.$store.dispatch('timeTable/showPopup')
      }
    },
    setInitialReservation(initialReservation, startTime) {
      const purposeTime = this.getReservationPurpose(
        initialReservation.reservationPurposeId
      ).time
      let endTime = moment(startTime, 'HHmm')
        .add(purposeTime, 'minutes')
        .format('HHmm')
      const date = this.timeTable.date
      if (
        initialReservation.startTime !== startTime ||
        initialReservation.date !== date ||
        initialReservation.reservationColumnId !== this.reservationColumn.id
      ) {
        this.tmpInitialReservation = {
          ...initialReservation,
          date,
          startTime,
          endTime,
          reservationColumnId: this.reservationColumn.id
        }
      } else {
        this.$store.dispatch('timeTable/setInitialReservation', {
          ...initialReservation,
          date,
          startTime,
          endTime,
          reservationColumnId: this.reservationColumn.id
        })
      }
    },
    dragStartReservation(e) {
      if (e.originalEvent.type === 'pointermove') {
        const beforeDragElementRect = e.item.getBoundingClientRect()
        const offSetX = e.originalEvent.clientX - beforeDragElementRect.left
        const offSetY = e.originalEvent.clientY - beforeDragElementRect.top
        const dragElement = document.querySelector(
          `.draggable-reservation-item-${this.reservationColumn.id} .sortable-drag`
        )
        dragElement.style.left =
          parseInt(dragElement.style.left.replace('px', '')) +
          offSetX -
          3 +
          'px'
        dragElement.style.top =
          parseInt(dragElement.style.top.replace('px', '')) + offSetY - 3 + 'px'
      }
      const dragReservation = this.$store.getters['reservations/getDataById'](
        parseInt(e.item.dataset.reservationItem)
      )
      this.$emit('start', dragReservation)
    },
    dragReservation(e) {
      if (
        this.$store.getters['petorelu/get'].browser.includes('firefox') ||
        (e.type !== 'drag' && e.type !== 'touchmove')
      ) {
        return
      }
      this.calculateTimeAndReservationColumn(
        e,
        this.dragReservationObj.reservation
      )
    },
    calculateTimeAndReservationColumn(e, reservation) {
      const defaultDragObj = {
        reservation: reservation,
        reservationColumnId: 0,
        startTime: '',
        endTime: ''
      }
      const toTarget =
        e.type === 'drag' || e.type === 'dragover'
          ? document.elementFromPoint(e.clientX, e.clientY)
          : document.elementFromPoint(
              e.changedTouches[0].clientX,
              e.changedTouches[0].clientY
            )
      if (toTarget === null) {
        this.$emit('drag-reservation', defaultDragObj)
        return
      }
      const toTime = toTarget.dataset.indicateLabel
      const toReservationColumn = toTarget.parentNode.dataset.columnId
      if (!toTime || !toReservationColumn) {
        this.$emit('drag-reservation', defaultDragObj)
        return
      }
      const offSetY =
        e.type === 'drag' || e.type === 'dragover'
          ? e.clientY - toTarget.getBoundingClientRect().top
          : e.changedTouches[0].clientY - toTarget.getBoundingClientRect().top
      const columnHeight = this.$store.getters['display/getData']
        .timeTableUnitHeight
      const timeUnit = this.$store.getters['reservationSetting/getData']
        .reservationTimeUnit
      const clickMinute =
        parseInt(((offSetY / columnHeight) * 60) / timeUnit) * timeUnit
      const startTime = toTime.substring(0, 2) + ('00' + clickMinute).slice(-2)
      const minuteDiff = moment(reservation.endTime, 'HHmm').diff(
        moment(reservation.startTime, 'HHmm'),
        'minutes'
      )
      const endTime = moment(startTime, 'HHmm')
        .add(minuteDiff, 'minutes')
        .format('HHmm')
      this.$emit(
        'drag-reservation',
        {
          reservation: reservation,
          reservationColumnId: parseInt(toReservationColumn),
          startTime,
          endTime
        },
        e
      )
    },
    dropReservation(e) {
      if (
        (this.dragReservationObj.reservationColumnId === 0 &&
          this.dragReservationObj.startTime === '' &&
          this.dragReservationObj.endTime === '') ||
        (e.originalEvent.type !== 'drop' && e.originalEvent.type !== 'touchend')
      ) {
        this.$emit('end')
        return
      }
      this.$store.dispatch('timeTable/setInitialReservation', {
        ...this.dragReservationObj.reservation,
        reservationColumnId: this.dragReservationObj.reservationColumnId,
        startTime: this.dragReservationObj.startTime,
        endTime: this.dragReservationObj.endTime
      })
      this.$emit('end')
      this.$store.dispatch('timeTable/showPopup')
    },
    startMoveEndTime(startTimeY, reservation) {
      this.moveEndTimeModeFlg = true
      this.moveEndTimeObj = {
        reservation: reservation,
        afterEndTime: reservation.endTime
      }
      this.moveEndTimeReservationId = reservation.id
      this.startTimeY = startTimeY
      this.initialScrollTop = this.value
    },
    moveEndTime(e, reservation) {
      if (this.$store.getters['petorelu/get'].browser.includes('firefox')) {
        return
      }
      this.calculateEndTime(e.clientY, reservation)
    },
    dragOverFireFox(e) {
      if (
        this.$store.getters['petorelu/get'].browser.includes('firefox') &&
        this.dragModeFlg &&
        e.type === 'dragover'
      ) {
        this.calculateTimeAndReservationColumn(
          e,
          this.dragReservationObj.reservation
        )
      } else if (
        this.$store.getters['petorelu/get'].browser.includes('firefox') &&
        this.moveEndTimeModeFlg
      ) {
        this.calculateEndTime(e.clientY, this.moveEndTimeObj.reservation)
      }
    },
    calculateEndTime(clientY, reservation) {
      if (clientY === 0) return
      if (this.clientY === clientY) return
      this.clientY = clientY
      const moveScrollY = this.value - this.initialScrollTop
      const positionAdjustmentForSafari = 3 // safariでドラッグ中スクロールが動くように調整
      const fiveMinutesHeight = Math.ceil(this.unitHeight / 12) + 1 // unitHeight = 140の時、13とする
      const height =
        clientY + moveScrollY + positionAdjustmentForSafari - this.startTimeY >
        fiveMinutesHeight
          ? clientY +
            moveScrollY +
            positionAdjustmentForSafari -
            this.startTimeY
          : fiveMinutesHeight
      const hourUnitEndTime =
        height / this.unitHeight + hourUnit(reservation.startTime)
      const hour = Math.floor(hourUnitEndTime)
      const decimal = parseFloat('0.' + ('' + hourUnitEndTime).split('.')[1])
      const minute =
        decimal !== 0
          ? Math.floor(parseInt(String(decimal * 60).substring(0, 2)) / 5) * 5
          : 0
      const endTime = ('00' + hour).slice(-2) + ('00' + minute).slice(-2)
      // ↓ドラッグによる予約終了時間の変更操作が重くならないよう、
      //  終了時間の値が変わった時だけ、data: moveEndTimeObjの値を変更しております
      if (this.moveEndTimeObj.afterEndTime === endTime) return
      this.moveEndTimeObj = {
        reservation: reservation,
        afterEndTime: endTime <= '2355' ? endTime : '2355'
      }
    },
    openWithChangedEndTime(reservation) {
      this.$store.dispatch('timeTable/setInitialReservation', {
        ...reservation,
        endTime: this.moveEndTimeObj.afterEndTime
      })
      this.$store.dispatch(
        'timeTable/setMoveEndTimeOfReservation',
        this.moveEndTimeObj
      )
      this.moveEndTimeModeFlg = false
      this.moveEndTimeObj = { reservation: { id: 0 }, afterEndTime: '' }
      this.moveEndTimeReservationId = 0
      this.startTimeY = 0
      this.initialScrollTop = 0
      this.clientY = 0
      this.$store.dispatch('timeTable/showPopup')
    },
    makeDifferentNewReservation() {
      this.alertFlg = false
      this.$emit('update-initial-reservation', this.tmpInitialReservation)
      this.$store.dispatch('timeTable/showPopup')
    },
    calcReservationLeftWidth() {
      const columnData = this.timeTable
      //重なり判定
      const isOverlap = this.isOverlap
      //連なりの融合
      const uniteSeqObjs = (seqObj1, seqObj2) => {
        return {
          seqId: Math.min(seqObj1.id, seqObj2.id),
          startTime:
            seqObj1.startTime < seqObj2.startTime
              ? seqObj1.startTime
              : seqObj2.startTime,
          endTime:
            seqObj1.endTime > seqObj2.endTime
              ? seqObj1.endTime
              : seqObj2.endTime,
          ress: seqObj1.ress.concat(seqObj2.ress)
        }
      }
      //重なりの連なり取得
      const makeSequence = checkReservations => {
        let seqObjs = checkReservations.map(r => {
          return {
            id: r.id,
            startTime: r.startTime,
            endTime: r.endTime,
            ress: [r.id]
          }
        })
        let renewFlg = true
        while (renewFlg) {
          renewFlg = false
          for (let i = 0; i < seqObjs.length; i++) {
            let seqObj1 = seqObjs[i]
            for (let j = i + 1; j < seqObjs.length; j++) {
              let seqObj2 = seqObjs[j]
              if (isOverlap(seqObj1, seqObj2)) {
                renewFlg = true
                let newSeqObj = uniteSeqObjs(seqObj1, seqObj2)
                seqObjs.splice(j, 1)
                seqObjs[i] = newSeqObj
                break
              }
            }
            if (renewFlg) {
              break
            }
          }
        }
        return seqObjs
      }
      //重なりの中で小列subColumnに分ける
      const makeSubColumnsArray = (seqObjs, nowRess) => {
        const makeSubColumnItem = res => {
          return {
            id: res.id,
            startTime: res.startTime,
            endTime: res.endTime
          }
        }
        let subColumnsArray = []
        //時間が重なる可能性のあるものそれぞれについて
        //subColumn: subColumn の中では時間が重ならない
        //subColumn = [ {id,startTime,endTime},{id,startTime,endTime}]
        //subColumns= [subColumn,subColumn]
        seqObjs.forEach(seqObj => {
          let subColumns = []
          for (let i = 0; i < seqObj.ress.length; i++) {
            let reservation = nowRess.find(r => r.id === seqObj.ress[i])
            let inSubFlg = false
            for (let j = 0; j < subColumns.length; j++) {
              // subColumn の中に時間が重なるものが一つもない場合、そのsubColumnに追加
              let subColumn = subColumns[j]
              if (
                subColumn.every(item => {
                  return isOverlap(item, reservation) === false
                })
              ) {
                subColumn.push(makeSubColumnItem(reservation))
                inSubFlg = true
                break
              }
            }
            //どの subColumn にも入らなかった場合新たに subColumnを作る
            if (inSubFlg === false) {
              subColumns.push([makeSubColumnItem(reservation)])
            }
          }
          subColumnsArray.push(subColumns)
        })
        return subColumnsArray
      }
      //subColumns をもとにleft,widthを求める
      const getResLeftWidth = subColumnsArray => {
        let leftWidthDict = {}
        let maxSubLength =
          subColumnsArray.length > 0 ? subColumnsArray[0].length : 0
        subColumnsArray.forEach(subColumns => {
          maxSubLength =
            maxSubLength > subColumns.length ? maxSubLength : subColumns.length
        })
        this.columnWidth = Math.max(
          maxSubLength * columnData.subColumnWidth + columnData.blankWidth,
          columnData.unitWidth
        )
        const columnAvailableWidth =
          maxSubLength > columnData.subColumnLength
            ? maxSubLength * columnData.subColumnWidth
            : columnData.availableWidth
        subColumnsArray.forEach(subColumns => {
          let subLength = subColumns.length
          let width = columnAvailableWidth / subLength
          width = Math.min(width, columnData.availableWidth)
          for (let subIndex = 0; subIndex < subColumns.length; subIndex++) {
            let subColumn = subColumns[subIndex]
            let left = width * subIndex
            subColumn.forEach(obj => {
              leftWidthDict[obj.id] = { id: obj.id, width, left }
            })
          }
        })
        return leftWidthDict
      }
      const checkReservations = this.reservations.map(v => {
        return {
          id: v.id,
          startTime: v.startTime,
          endTime: v.endTime,
          left: 0,
          width: 0,
          sequenceId: 0
        }
      })
      const seqObjs = makeSequence(checkReservations)
      const subColumnsArray = makeSubColumnsArray(seqObjs, this.reservations)
      const leftWidthDict = getResLeftWidth(subColumnsArray)
      this.$emit('setWidth', {
        id: this.reservationColumn.id,
        width: this.columnWidth
      })
      return leftWidthDict
    }
  }
}
</script>
<style lang="scss" scoped>
.reservation-column {
  position: relative;
  > .column-name {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 26px;
    background-color: #{$white};
    pointer-events: none;
  }
  > .column-square {
    border-bottom: solid 1px #{$light-grey};
    border-left: solid 1px #{$light-grey};
    box-sizing: border-box;
  }
  > .column-square-header {
    border-bottom: solid 1px #{$light-grey};
    border-left: solid 1px #{$light-grey};
    background-color: #{$medium-grey};
  }
  > .column-square-footer {
    border-left: solid 1px #{$light-grey};
    background-color: #{$medium-grey};
  }
  > .off-time {
    position: absolute;
    width: 100%;
    background-color: #{$medium-grey};
    left: 0;
    z-index: -10;
  }
  > .request-block {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    z-index: -5;
    width: 100%;
    background-color: #ccffff;
  }
  > .outside-working-time {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    z-index: -20;
    width: 100%;
    background-color: #{$medium-grey};
  }
  > .working-time {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    z-index: -15;
    width: 100%;
    background-color: #{$white};
  }
}
.reservation-column:last-child {
  > .column-square {
    border-right: solid 1px #{$light-grey};
  }
  > .column-square-header {
    border-right: solid 1px #{$light-grey};
  }
  > .column-square-footer {
    border-right: solid 1px #{$light-grey};
  }
}
</style>
