<template>
  <div
    class="reservation-item"
    data-test="reservation-item"
    :class="highlightClass"
    :style="styles"
    @click="displayReservation"
    :title="dialogText"
    :ref="`reservationItem${reservation.id}`"
    @dragstart="changeCursorPosition"
    @drag="dragReservation"
    @touchmove="dragReservation"
    data-e2e="reservation-item"
  >
    <div class="reservation-item-box" :style="{ height: styles.height }">
      <div class="care-time-and-status">
        <div class="care-time">{{ time }}</div>
        <div class="status" :style="statusStyle">
          <span class="status-text">{{ waitingStatus.name }}</span>
          <span v-if="existsWarningToPayment" class="exclamation-mark">
            <div class="wrap">
              <span class="circle" :style="{ borderColor: statusStyle.color }">
                <span class="mark">!</span>
              </span>
            </div>
          </span>
        </div>
        <div
          class="status"
          :style="`color: ${styles.backgroundColor}`"
          v-if="first"
        >
          初
        </div>
      </div>
      <div class="owner-name">{{ ownerLastName + ' ' + ownerFirstName }}</div>
      <div class="patient-info">{{ patientInfo }}</div>
      <div class="show-id">{{ showId }}</div>
      <div class="reservation-purpose">{{ reservationPurpose }}</div>
      <div class="memo">{{ 'メモ：' + reservation.memo }}</div>
      <div
        v-if="pcFlg"
        class="drag-end-time-area"
        :class="{ 'no-karte': reservation.medicalRecordOriginalId === 0 }"
        :draggable="reservation.medicalRecordOriginalId === 0"
        @dragstart.stop="setBlankElement"
        @drag.stop="moveEndTime"
        @dragend="openWithChangedEndTime"
      ></div>
    </div>
  </div>
</template>

<script>
import dedent from 'dedent'
import moment from 'moment'
import { canHavePayment } from '@/utils/medical_payment_helper'
import { diffTop } from '@/utils/time_table'
import { getDevice } from '@/utils/get_device'
import { makePaymentDetail } from '@/utils/price_calculation'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationItem',

  props: {
    moveEndTimeObj: { type: Object, default: null },
    reservationDatum: { type: Object },
    reservationStyles: { type: Object },
    width: { type: String }
  },

  data() {
    return {
      emptyDragImage: this.createEmptyDragImage(),
      first: '',
      ownerFirstName: '',
      ownerLastName: '',
      showId: ''
    }
  },

  computed: {
    ...mapGetters({
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getMedicalPaymentsByRecordOriginalId:
        'medicalPayments/getDataByMedicalRecordOriginalId',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId',
      karteFlg: 'auth/karteFlg',
      waitingStatusesIncludeDel: 'waitingStatuses/getDataIncludeDel'
    }),
    reservation() {
      return this.moveEndTimeObj &&
        this.moveEndTimeObj.afterEndTime.length !== 0
        ? {
            ...this.reservationDatum,
            endTime: this.moveEndTimeObj.afterEndTime
          }
        : this.reservationDatum
    },
    styles() {
      const itemH = diffTop(
        this.reservation.endTime,
        this.reservation.startTime,
        this.$store.getters['display/getData'].timeTableUnitHeight
      )
      return { ...this.reservationStyles, height: itemH - 1 + 'px' }
    },
    pcFlg() {
      return getDevice() === 'pc' ? true : false
    },
    time() {
      const startTime = this.reservation.startTime
      const endTime = this.reservation.endTime
      return `${startTime.slice(0, 2)}:${startTime.slice(2)} - ${endTime.slice(
        0,
        2
      )}:${endTime.slice(2)}`
    },
    reservationPurpose() {
      return this.reservation.reservationPurposeId !== 0
        ? this.$store.getters['reservationPurposes/getDataById'](
            this.reservation.reservationPurposeId
          ).name
        : ''
    },
    statusStyle() {
      return this.styles.color === '#ffffff'
        ? { color: this.styles.backgroundColor }
        : { color: this.styles.color }
    },
    owner() {
      return this.reservation.ownerId !== 0
        ? this.$store.getters['owners/getDataById'](this.reservation.ownerId)
        : {}
    },
    patientInfo() {
      if (this.reservation.patientId !== 0) {
        const patient = this.$store.getters['patients/getDataById'](
          this.reservation.patientId
        )
        const species =
          patient.speciesId !== 0
            ? this.$store.getters['species/getDataById'](patient.speciesId).name
            : ''
        if (patient.birthday) {
          const age = this.calcAge(patient)
          return `${patient.name} (${species}) ${age}`
        } else {
          return `${patient.name} (${species})`
        }
      } else {
        return this.reservation.patientName
      }
    },
    dialogText() {
      const firstReservationMark = this.first ? ' 初' : ''
      return dedent`
        ${this.time} ${this.waitingStatus.name}${firstReservationMark}
        ${this.ownerLastName + ' ' + this.ownerFirstName}
        ${this.patientInfo}
        ${this.showId}
        ${this.reservationPurpose}
        ${'メモ：' + this.reservation.memo}`
    },
    medicalRecordAssociatedWithReservation() {
      if (this.karteFlg) {
        const medicalRecord = this.getMedicalRecordByOriginalId(
          this.reservation.medicalRecordOriginalId
        )
        return medicalRecord ? medicalRecord : null
      } else {
        return null
      }
    },
    medicalPaymentAssociatedWithReservation() {
      if (this.karteFlg && this.medicalRecordAssociatedWithReservation) {
        const medicalPaymentData = this.getMedicalPaymentsByRecordOriginalId(
          this.medicalRecordAssociatedWithReservation.originalId
        )
        return medicalPaymentData ? medicalPaymentData[0] : null
      } else {
        return null
      }
    },
    paymentType() {
      const medicalPayment = this.medicalPaymentAssociatedWithReservation
      if (this.karteFlg && canHavePayment(medicalPayment)) {
        const { type } = makePaymentDetail(
          this.medicalPaymentAssociatedWithReservation,
          this.getMedicalPaymentHistory,
          this.getMedicalRecordByOriginalId,
          this.getPaymentsByMedicalPaymentId
        )
        return type
      } else return ''
    },
    highlightClass() {
      const highlightedReservation = this.$store.getters['timeTable/get']
        .highlightedReservation
      if (!highlightedReservation) return ''
      let highlightClass = ''
      if (
        highlightedReservation.type === 'createUpdate' &&
        highlightedReservation.ids.includes(this.reservationDatum.id)
      ) {
        // 予約ポップアップで登録・変更時(予約が複数の場合もある)
        highlightClass = 'timed-blink'
      } else if (highlightedReservation.id === this.reservationDatum.id) {
        // 右メニューで待合状況をマウスオーバーした時
        highlightClass = 'blink'
      }
      return highlightClass
    },
    existsWarningToPayment() {
      return this.hasAfterPaymentWaitingStatus && this.hasIncompletePayment
    },
    hasAfterPaymentWaitingStatus() {
      const afterPaymentWaitingStatusIds = this.waitingStatusesIncludeDel
        .filter(v => v.paymentCreateWaitingStatusId)
        .map(v => v.paymentCreateWaitingStatusId)
      return afterPaymentWaitingStatusIds.includes(this.waitingStatus.id)
    },
    hasIncompletePayment() {
      return ['未会計', '未収金', '再会計'].includes(this.paymentType)
    },
    waitingStatus() {
      return this.$store.getters['waitingStatuses/getDataById'](
        this.reservation.waitingStatusId
      )
    }
  },

  watch: {
    owner: function(after, before) {
      this.setOwnerEachValue(after, before) // if文内の各処理のテストをできるようにするため、処理をmethodsにしました。
    },
    reservation: function(after, before) {
      if (after.ownerId !== before.ownerId) {
        this.setFirst(this.owner, after)
        this.setShowId(this.owner, after)
      }
      if (
        after.ownerId !== before.ownerId ||
        after.ownerLastName !== before.ownerLastName
      ) {
        this.setOwnerLastName(this.owner, after)
      }
      if (
        after.ownerId !== before.ownerId ||
        after.ownerFirstName !== before.ownerFirstName
      ) {
        this.setOwnerFirstName(this.owner, after)
      }
    }
  },

  created() {
    // ownerとpatientのデータが大量にある時、ownerの各値を表示する処理をcomputedで書くと、
    // ドラッグ中のスクロール移動と終了時間のドラッグの動作が重くなるため、dataに保持するようにしています。
    this.setShowId(this.owner, this.reservation)
    this.setFirst(this.owner, this.reservation)
    this.setOwnerLastName(this.owner, this.reservation)
    this.setOwnerFirstName(this.owner, this.reservation)
  },

  beforeDestroy() {
    this.unhighlightReservation()
  },

  methods: {
    setOwnerEachValue(after, before) {
      if (Object.keys(after).length > 0 && Object.keys(before).length > 0) {
        if (after.showId !== before.showId) {
          this.setFirst(after, this.reservation)
          this.setShowId(after, this.reservation)
        }
        if (after.lastName !== before.lastName) {
          this.setOwnerLastName(after, this.reservation)
        }
        if (after.firstName !== before.firstName) {
          this.setOwnerFirstName(after, this.reservation)
        }
      }
    },
    setShowId(owner, reservation) {
      this.showId =
        reservation.ownerId !== 0 && owner.showId !== ''
          ? '飼主ID：' + owner.showId
          : ''
    },
    setFirst(owner, reservation) {
      this.first = reservation.ownerId !== 0 && owner.showId === ''
    },
    setOwnerLastName(owner, reservation) {
      this.ownerLastName =
        reservation.ownerId !== 0 ? owner.lastName : reservation.ownerLastName
    },
    setOwnerFirstName(owner, reservation) {
      this.ownerFirstName =
        reservation.ownerId !== 0 ? owner.firstName : reservation.ownerFirstName
    },
    createEmptyDragImage() {
      // 参考記事↓ ドラッグによる予約終了時間変更機能/safariで機能するように対応
      // https://stackoverflow.com/questions/48973815/javascript-html5-drag-events-not-firing-on-safari-mac-dragging-does-not-work?noredirect=1&lq=1
      const img = document.createElement('img')
      img.src =
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      return img
    },
    changeCursorPosition(e) {
      if (this.reservation.medicalRecordOriginalId === 0) {
        e.dataTransfer.setDragImage(e.target, 2, 2)
      }
    },
    dragReservation(e) {
      this.$emit('drag-reservation', e)
    },
    setBlankElement(e) {
      if (this.reservation.medicalRecordOriginalId === 0) {
        e.dataTransfer.setDragImage(this.emptyDragImage, 0, 0)
        const startTimeY = this.$refs[
          `reservationItem${this.reservation.id}`
        ].getBoundingClientRect().top
        this.$emit('start-move-end-time', startTimeY)
      }
    },
    moveEndTime(e) {
      if (this.reservation.medicalRecordOriginalId === 0) {
        this.$emit('move-end-time', e)
      }
    },
    openWithChangedEndTime(e) {
      this.$emit('open-with-changed-end-time', e)
    },
    displayReservation() {
      this.$emit('click', this.reservation.id)
      this.unhighlightReservation()
    },
    calcAge(patient) {
      const latestDate = patient.deathDate
        ? moment(patient.deathDate)
        : moment()
      const y =
        latestDate.diff(moment(patient.birthday), 'years') > 0
          ? latestDate.diff(moment(patient.birthday), 'years')
          : 0
      return patient.birthday.length === 8 ? y + '歳' : '推定' + y + '歳'
    },
    unhighlightReservation() {
      this.$store.dispatch('timeTable/resetHighlightedReservation')
    }
  }
}
</script>
<style lang="scss" scoped>
.reservation-item {
  color: #{$white};
  font-size: 12px;
  text-align: left;
  position: absolute;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  > .reservation-item-box {
    position: relative;
    padding: 2px 12px;
    > .care-time-and-status {
      display: flex;
      > .status {
        background: #{$white};
        padding: 0 3px;
        margin-left: 5px;
        > .status-text {
          display: inline-block;
          vertical-align: text-top;
          line-height: 17.6px;
        }
        > .exclamation-mark {
          display: inline-block;
          vertical-align: text-top;
          line-height: 17px;
          > .wrap {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            height: 17.6px;
            padding-top: 0.5px;
            > .circle {
              box-sizing: border-box;
              position: relative;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: 1px solid;
              line-height: 9px;
              > .mark {
                position: absolute;
                display: inline-block;
                left: 0;
                top: 0;
                width: 10px;
                font-size: 9px;
                text-align: center;
              }
            }
          }
        }
      }
    }
    > .drag-end-time-area {
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: 0;
      left: 0;
      &.no-karte {
        cursor: ns-resize;
      }
    }
  }
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &.blink {
    animation: blinker 1.5s linear infinite;
  }
  &.timed-blink {
    animation: blinker 1.5s linear 3;
  }
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
</style>
