<template>
  <div class="main-right-menu" :class="{ wide: wide }">
    <div class="menu-head">
      <div class="icon">
        <img
          class="three-line"
          src="@/assets/images/menu.png"
          width="27px"
          height="20px"
          alt="menu"
          data-e2e="right-menu-toggle-button"
          @click="toggleWide"
        />
      </div>
    </div>
    <div v-show="wide" class="calendar">
      <main-right-calendar
        :weekTableDisplayFlg="weekTableDisplayFlg"
        @error="openErrorAnnounceCalendar"
      />
    </div>
    <div
      v-show="wide"
      class="menu-item-list"
      :style="{ '--speed': toggleSpeed + 's' }"
    >
      <div class="folding reservation-status-manage">
        <div class="header" @click="rsOpenFlg = !rsOpenFlg">
          <div class="title">待合状況</div>
          <div class="arrow" :class="{ up: rsOpenFlg, down: !rsOpenFlg }"></div>
        </div>
        <transition name="reservation-statuses">
          <div
            v-show="rsOpenFlg"
            class="body"
            ref="reservationStatuses"
            :style="{ '--rs-height': rsHeight + 'px' }"
          >
            <reservation-statuses />
          </div>
        </transition>
      </div>
      <div class="folding reservation-column-manage">
        <div class="header" @click="rcOpenFlg = !rcOpenFlg">
          <div class="title">予約列表示</div>
          <div class="arrow" :class="{ up: rcOpenFlg, down: !rcOpenFlg }"></div>
        </div>
        <transition name="reservation-columns">
          <div
            v-show="rcOpenFlg"
            class="show"
            ref="reservationColumns"
            :style="{ '--rc-height': rcHeight + 'px' }"
          >
            <reservation-column-links
              class="links"
              @sort="sortMode"
              @popup="openPrintPopup"
            />
            <div class="slide-tab-area">
              <draggable
                class="draggable"
                :sort="sortModeFlg"
                v-model="displayBodyData"
              >
                <reservation-column-slide-tab
                  class="slide-tab"
                  v-for="(datum, index) in displayBodyData"
                  v-bind="datum"
                  :key="datum.reservationColumnGroupId"
                  :index="index"
                  :sortModeFlg="sortModeFlg"
                  @order="changeBodyDataAndOrderIds"
                  @error="openErrorAnnounce"
                />
              </draggable>
            </div>
            <div class="button-wrapper">
              <base-button-small-white
                v-show="sortModeFlg"
                @click="cancelSortMode"
                >キャンセル</base-button-small-white
              >
              <base-button-small-orange
                v-show="sortModeFlg && lookOnlyFlg === 0"
                :disabled="waitFlg"
                @click="updateOrder"
                >登録</base-button-small-orange
              >
            </div>
          </div>
        </transition>
      </div>
    </div>
    <print-popup v-if="cover" @close="closePrintPopup" />
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :type="type"
      @close="closeAlert"
      >{{ popupMessage }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import MainRightCalendar from '@/components/parts/organisms/MainRightCalendar'
import ReservationStatuses from '@/components/parts/organisms/ReservationStatuses'
import ReservationColumnLinks from '@/components/parts/organisms/ReservationColumnLinks'
import ReservationColumnSlideTab from '@/components/parts/organisms/ReservationColumnSlideTab'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseButtonSmallWhite from '../atoms/BaseButtonSmallWhite.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import _ from 'lodash'

export default {
  name: 'MainRightMenu',
  components: {
    MainRightCalendar,
    ReservationStatuses,
    ReservationColumnLinks,
    ReservationColumnSlideTab,
    Draggable,
    BaseButtonSmallOrange,
    PrintPopup: () => import('@/components/popups/PrintPopup'),
    AnnouncePopup,
    UnsavedLeavePopup,
    BaseButtonSmallWhite
  },
  mixins: [CheckInputDifference],

  props: {
    weekTableDisplayFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      cover: false,
      sortModeFlg: false,
      staffReservationColumnOrderIds: [],
      reservationColumnGroupIndexes: [],
      bodyData: [],
      beforeBodyData: [],
      alertFlg: false,
      waitFlg: false,
      type: 'failure',
      title: '失敗',
      popupMessage: '',
      buttons: ['閉じる'],
      rsOpenFlg: true,
      rsObserver: null,
      rsHeight: 0,
      rcObserver: null,
      rcOpenFlg: true,
      rcHeight: 0,
      toggleSpeed: 0.3
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getReservationColumnGroup: 'reservationColumnGroups/getDataById',
      getStaffReservationColumn: 'staffReservationColumns/getDataByColumnId'
    }),
    reservationColumns() {
      return _.cloneDeep(this.$store.getters['reservationColumns/getData'])
    },
    wide() {
      return this.$store.getters['petorelu/get'].rightWideFlg
    },
    displayBodyData: {
      get() {
        return this.sortModeFlg ? this.bodyData : this.beforeBodyData
      },
      set(data) {
        this.bodyData = data
        this.staffReservationColumnOrderIds = this.makeStaffReservationColumnOrderIds()
        this.reservationColumnGroupIndexes = this.makeReservationColumnGroupIndexes()
        this.mixinInputData = {
          staffReservationColumnOrderIds: this.staffReservationColumnOrderIds,
          reservationColumnGroupIndexes: this.reservationColumnGroupIndexes
        }
      }
    }
  },

  watch: {
    sortModeFlg: function() {
      this.setData()
    }
  },

  created() {
    this.setData()
    this.$store.watch(
      () => this.$store.getters['staffReservationColumns/getData'],
      () => this.setData()
    )
    this.$store.watch(
      () => this.$store.getters['reservationColumns/getData'],
      () => this.setData()
    )
    this.$store.watch(
      () => this.$store.getters['reservationColumnGroups/getData'],
      () => this.setData()
    )
  },

  mounted() {
    this.rsObserver = new ResizeObserver(() => {
      this.rsHeight = this.$refs.reservationStatuses.scrollHeight
    })
    this.rsObserver.observe(this.$refs.reservationStatuses)
    this.rcObserver = new ResizeObserver(() => {
      this.rcHeight = this.$refs.reservationColumns.scrollHeight
    })
    this.rcObserver.observe(this.$refs.reservationColumns)
  },

  beforeDestroy() {
    this.rsObserver.disconnect()
    this.rcObserver.disconnect()
  },

  methods: {
    setData() {
      if (!this.sortModeFlg) {
        this.bodyData = this.makeBodyData()
        this.sortBodyData()
        this.staffReservationColumnOrderIds = this.makeStaffReservationColumnOrderIds()
        this.reservationColumnGroupIndexes = this.makeReservationColumnGroupIndexes()
        this.mixinInitialData = {
          staffReservationColumnOrderIds: _.cloneDeep(
            this.staffReservationColumnOrderIds
          ),
          reservationColumnGroupIndexes: _.cloneDeep(
            this.reservationColumnGroupIndexes
          )
        }
        this.mixinInputData = {
          staffReservationColumnOrderIds: this.staffReservationColumnOrderIds,
          reservationColumnGroupIndexes: this.reservationColumnGroupIndexes
        }
        this.beforeBodyData = _.cloneDeep(this.bodyData)
      }
    },
    makeBodyData() {
      const usedReservationColumnGroupIds = this.reservationColumns
        .map(v => v.reservationColumnGroupId)
        .filter((x, i, self) => self.indexOf(x) === i)
      const usedReservationColumnGroups = usedReservationColumnGroupIds.map(i =>
        this.getReservationColumnGroup(i)
      )
      let bodyData = []
      if (this.reservationColumns.length !== 0) {
        bodyData = usedReservationColumnGroups.map(v => {
          const sameGroupReservationColumns = this.reservationColumns.filter(
            x => x.reservationColumnGroupId === v.id
          )
          const groupOrder = this.getStaffReservationColumn(
            sameGroupReservationColumns[0].id
          ).order
          sameGroupReservationColumns.forEach(x => {
            const staffReservationColumn = this.getStaffReservationColumn(x.id)
            x.staffReservationColumnId = staffReservationColumn.id
            x.order = staffReservationColumn.order
          })
          sameGroupReservationColumns.sort((a, b) => a.order - b.order)
          return {
            groupOrder: groupOrder,
            reservationColumnGroupId: v.id,
            reservationColumnGroupName: v.name,
            reservationColumns: sameGroupReservationColumns
          }
        })
      }
      return bodyData
    },
    sortBodyData() {
      this.bodyData.sort((a, b) => a.groupOrder - b.groupOrder)
    },
    makeStaffReservationColumnOrderIds() {
      return this.bodyData.map(data => {
        return data.reservationColumns.map(v => v.staffReservationColumnId)
      })
    },
    makeReservationColumnGroupIndexes() {
      return Array.from(new Array(this.bodyData.length)).map((_, i) => i)
    },
    toggleWide() {
      this.$store.dispatch('petorelu/toggleRight')
    },
    sortMode() {
      this.sortModeFlg = true
    },
    cancelSortMode() {
      this.sortModeFlg = false
    },
    changeBodyDataAndOrderIds(obj) {
      this.$set(this.bodyData[obj.index], 'reservationColumns', obj.data)
      this.$set(this.staffReservationColumnOrderIds, obj.index, obj.orderIds)
    },
    async updateOrder() {
      this.waitFlg = true
      const orderIds = this.reservationColumnGroupIndexes.map(
        v => this.staffReservationColumnOrderIds[v]
      )
      const result = await this.$store.dispatch(
        'staffReservationColumns/updateOrder',
        orderIds
      )
      if (result === true) {
        this.sortModeFlg = false
        this.setData()
      } else {
        if (result === 'no data') {
          this.popupMessage = `予約列データが変更されています。\n最新データに更新しますので再編集をお願いします。`
          this.setData()
        } else {
          this.popupMessage = '並び順登録に失敗しました'
        }
        this.alertFlg = true
      }
      this.waitFlg = false
    },
    openPrintPopup() {
      this.cover = true
    },
    closePrintPopup() {
      this.cover = false
    },
    closeAlert() {
      this.alertFlg = false
      this.sortModeFlg = false
    },
    openErrorAnnounceCalendar() {
      this.popupMessage = '過去の予約の取得に失敗しました'
      this.alertFlg = true
    },
    async openErrorAnnounce(result) {
      this.popupMessage =
        result === 'no staffReservationColumn'
          ? `既に削除された予約列が使用されています。\n最新データに更新しますので再編集をお願いします。`
          : '通信エラーが発生しました'
      this.setData()
      this.alertFlg = true
    }
  }
}
</script>

<style lang="scss" scoped>
.main-right-menu {
  box-sizing: border-box;
  padding-bottom: 50px;
  width: 58px;
  min-width: 58px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: white;
  border-left: solid 1px #{$light-grey};
  &.wide {
    width: 348px;
    min-width: 348px;
  }
  > .menu-head {
    display: flex;
    justify-content: left;
    > .icon {
      width: 57px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      > .three-line {
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }
  > .menu-item-list {
    margin-top: 20px;
    box-sizing: border-box;
    width: 100%;
    padding-left: 15px;
    > .folding {
      > .header {
        width: 315px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
        > .title {
          font-size: 20px;
          font-weight: bold;
          text-align: left;
        }
        > .arrow {
          margin-right: 10px;
          width: 20px;
          height: 10px;
          clip-path: polygon(0 100%, 50% 0, 100% 100%);
          background-color: rgb(171, 171, 171);
          &.up {
            transition: all var(--speed);
          }
          &.down {
            transition: all var(--speed);
            transform: rotate(180deg);
          }
        }
      }
      > .body {
        padding-bottom: 30px;
      }
    }
    > .reservation-column-manage {
      margin-top: 15px;
      > .show {
        > .links {
          padding-top: 18px;
        }
        > .slide-tab-area {
          > .draggable {
            > .slide-tab {
              margin-top: 20px;
            }
          }
        }
        > .button-wrapper {
          margin-top: 30px;
          height: 33px;
          width: 315px;
          display: flex;
          justify-content: center;
          gap: 0 20px;
        }
      }
    }
  }
}

.reservation-statuses-enter-active,
.reservation-columns-enter-active {
  transition-duration: var(--speed);
  transition-timing-function: ease-in;
}
.reservation-statuses-leave-active,
.reservation-columns-leave-active {
  transition-duration: var(--speed);
  transition-timing-function: ease-in;
}
.reservation-statuses-enter-to,
.reservation-statuses-leave {
  max-height: var(--rs-height);
  overflow: hidden;
}
.reservation-columns-enter-to,
.reservation-columns-leave {
  max-height: var(--rc-height);
  overflow: hidden;
}
.reservation-statuses-enter,
.reservation-statuses-leave-to,
.reservation-columns-enter,
.reservation-columns-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
