<template>
  <div class="reservation-column-links">
    <div class="top">
      <div
        class="add-link"
        @click="pushToReservationColumnNew"
        data-test="add-link"
      >
        <div class="icon-wrap">
          <img
            src="@/assets/images/plus.png"
            width="18px"
            height="18px"
            alt="plus"
          />
        </div>
        <div class="text">予約列登録</div>
      </div>
      <div
        class="setting-link"
        @click="pushToReservationColumnsTab"
        data-test="setting-link"
      >
        <div class="icon-wrap">
          <img
            src="@/assets/images/stack.png"
            width="16px"
            height="18px"
            alt="stack"
          />
        </div>
        <div class="text">予約列管理</div>
      </div>
    </div>
    <div class="bottom">
      <div class="print-link" @click="openPrintPopup" data-test="print">
        <div class="icon-wrap">
          <img
            src="@/assets/images/printer.png"
            width="19px"
            height="18px"
            alt="printer"
          />
        </div>
        <div class="text">予約表の印刷</div>
      </div>
      <div class="sort-link" @click="sortMode" data-test="sort">
        <div class="icon-wrap">
          <img
            src="@/assets/images/up_down_allow.png"
            width="16px"
            height="18px"
            alt="up-down-allow"
          />
        </div>
        <div class="text">表示の並び替え</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReservationColumnLinks',
  methods: {
    pushToReservationColumnNew() {
      this.$router.push({ name: 'reservation-column-new' })
    },
    pushToReservationColumnsTab() {
      this.$router.push({ name: 'reservation-columns-tab' })
    },
    openPrintPopup() {
      this.$emit('popup')
    },
    sortMode() {
      this.$emit('sort')
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin link() {
  display: flex;
  width: 100%;
  height: 18px;
  line-height: 18px;
  > .icon-wrap {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
  }
  > .text {
    margin-left: 7px;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.reservation-column-links {
  width: 315px;
  font-size: 13px;
  color: #{$gray};
  > .top {
    height: 20px;
    display: flex;
    justify-content: space-between;
    > .add-link {
      @include link();
    }
    > .setting-link {
      @include link();
    }
  }
  > .bottom {
    height: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    > .print-link {
      @include link();
    }
    > .sort-link {
      @include link();
    }
  }
}
</style>
