<template>
  <div class="main">
    <main-header />
    <div class="main-body">
      <main-left-menu />
      <router-view />
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/parts/organisms/MainHeader'
import MainLeftMenu from '@/components/parts/organisms/MainLeftMenu'

export default {
  name: 'Main',

  components: {
    MainHeader,
    MainLeftMenu
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  flex-grow: 1;
  overflow: hidden;
  > .main-body {
    display: flex;
    flex-direction: row;
    min-width: 0;
    min-height: 0;
    flex-grow: 1;
  }
}
</style>
