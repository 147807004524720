<template>
  <div class="time-table-header-search-box" @keyup.enter.stop="search">
    <input
      type="text"
      placeholder="飼主ID、飼主名、患者ID、患者名、住所、 電話番号"
      v-model="text"
      data-e2e="time-table-header-search-box"
    />
    <div
      data-test="search"
      @click="search"
      data-e2e="time-table-header-search-button"
    >
      検索
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeTableHeaderSearchBox',
  data() {
    return {
      text: ''
    }
  },
  methods: {
    search() {
      this.$router.push({ name: 'owners', params: { text: this.text } })
    }
  }
}
</script>

<style lang="scss" scoped>
.time-table-header-search-box {
  height: 33px;
  width: 340px;
  display: flex;
  > input {
    width: 282px;
    height: 33px;
    font-size: 11px;
    padding-left: 15px;
    box-sizing: border-box;
    border: solid 2px #{$light-grey};
    border-top-left-radius: 17px;
    -webkit-border-top-left-radius: 17px;
    -moz-border-radius-topleft: 17px;
    border-bottom-left-radius: 17px;
    -webkit-border-bottom-left-radius: 17px;
    -moz-border-radius-bottomleft: 17px;
  }
  > div {
    height: 33px;
    width: 58px;
    box-sizing: border-box;
    color: #{$white};
    border: solid 2px #{$pumpkin};
    border-left: none;
    background-color: #{$pumpkin};
    text-align: right;
    font-size: 13px;
    line-height: 29px;
    font-weight: bold;
    padding-right: 15px;
    border-top-right-radius: 17px;
    -webkit-border-top-right-radius: 17px;
    -moz-border-radius-topright: 17px;
    border-bottom-right-radius: 17px;
    -webkit-border-bottom-right-radius: 17px;
    -moz-border-radius-bottomright: 17px;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
@media (max-width: $tablet-width) {
  .time-table-header-search-box > div {
    width: 50px;
  }
}
</style>
